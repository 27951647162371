@charset "UTF-8";
/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb 
        - Preloader   
        - Scroll top 
    02. Section Spacing Css
    03. Custom Animation Css
    04. container Css 
    05. Header Css 
        - language
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
    06. Footer Css
    07. Sidebar Css
    08. Product style Css   
    09. Cart Page Css  
    10. Checkout Page Css 
    11. Services Css
    12. Services Css
    13. Counters Css 
    14. Listing Css
    15. Call To Action Css
    16. Testimonial slider Css
    17. Team Css 
    18. Projcet Css
    19. Timeline Css
    20. Contact Us Css
    21. Pricing  Css   
    22. Corporate Pages Css       
    23. Personal Pages Css 
    24. Creative Pages Css
    25. Architecture Pages Css    
    26. StartUp Pages Css
    27. Blog Pages Css   
    28. Projct Details Pages Css
    29. Preview Page Css 
    
/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
@import url("https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900&display=swap");
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Merriweather", serif;
  color: #172541;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 63px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 39px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 13px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #f3525a;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

.font-weight--ex-bold {
  font-weight: 800;
}

.font-weight--bold {
  font-weight: 600;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

.letter-space-1 {
  letter-spacing: 1px;
}

.letter-space-2 {
  letter-spacing: 2px;
}

.font-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

/*--
    Default Class
*/
.text-color-primary, .title--default {
  color: #273272;
}

.text-color-secondary {
  color: #f3525a;
}

.text-black {
  color: #333333 !important;
}

.bg-white {
  background: #ffffff;
}

.bg-gray {
  background: #F8F8F8;
}

.bg-gray-2 {
  background: #f1f1f1;
}

.bg-gray-3 {
  background: #f6f2ed;
}

.theme-bg-default {
  background: #273272;
}

.theme-bg-secondary {
  background: #f3525a;
}

.theme-bg-three {
  background: #172541;
}

.theme-color-default {
  color: #273272;
}

.theme-color-secondary {
  color: #f3525a;
}

.theme-color-three {
  color: #172541;
}

.transparent-bg {
  background: rgba(255, 255, 255, 0.85);
}

.sub-heading {
  color: #6d70a6;
}

.heading-title {
  color: #273272;
}

.black-bg {
  background: #000000;
}

.border-radus-5 {
  border-radius: 5px;
}

.text-green {
  color: #f3525a;
}

.bg-gradient {
  background: -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

.x-index-1 {
  z-index: 1;
}

.overflow-hiden {
  overflow: hidden;
}

.box-shadow--default {
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.section-title {
  color: #273272;
}

.section-sub-title {
  color: #f3525a;
  letter-spacing: 2px;
  line-height: 1.43;
  margin-top: -5px;
  margin-bottom: 5px;
}

.section-under-heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.40;
  color: #333333;
}

.section-under-heading a {
  color: #273272;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.section-under-heading a::before {
  content: '';
  width: 0;
  height: 1px;
  bottom: -1px;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.section-under-heading a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.bg-img {
  background-color: #ddd;
}

.img-width {
  width: 100%;
}

.title-dec-text {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

::selection {
  color: #ffffff;
  background-color: #273272;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #273272;
  border-color: #273272;
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.input-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.input-col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.input-col-5 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 575px) {
  .input-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.input-col-3 {
  -ms-flex: 0 0 33%;
  -webkit-box-flex: 0;
  flex: 0 0 33%;
  max-width: 33%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Overlay styles */
[data-overlay],
[data-secondary-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-secondary-overlay] > div,
[data-secondary-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-secondary-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background-color: #273272;
}

[data-secondary-overlay]:before {
  background-color: #f3525a;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-secondary-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-secondary-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-secondary-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-secondary-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-secondary-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-secondary-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-secondary-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-secondary-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-secondary-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-secondary-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
}

.swiper-pagination--vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  bottom: auto;
  left: auto;
}

.swiper-pagination--vertical .swiper-pagination-bullet {
  display: block;
  margin-bottom: 10px;
  line-height: 1;
  margin-right: 0;
}

.swiper-pagination--vertical .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 0;
  background: transparent;
  opacity: 1;
  position: relative;
  outline: none;
  margin-right: 20px;
}

.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  z-index: 1;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #273272;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #273272;
}

.site-wrapper-reveal {
  background-color: #ffffff;
}

.swiper-nav-button {
  background-image: none;
  text-align: center;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  margin: 0;
  bottom: 0;
  /*&::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: inherit;
        color: #fff;
        background: #fff;
    }*/
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  padding-top: 260px;
  padding-bottom: 184px;
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .breadcrumb-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.creative_breadcrumb-area {
  padding-top: 220px;
  padding-bottom: 120px;
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .creative_breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .creative_breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .creative_breadcrumb-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.architecture_breadcrumb-area {
  padding-top: 350px;
  padding-bottom: 120px;
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .architecture_breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .architecture_breadcrumb-area {
    padding-top: 174px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .architecture_breadcrumb-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.breadcrumb-list {
  margin-top: 20px;
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.58;
  text-transform: uppercase;
}

.breadcrumb-list li a {
  position: relative;
}

.breadcrumb-list li a::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list li.active {
  color: #6D70A6;
}

.page-pagination li {
  display: inline-block;
  color: #273272;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 7px;
  display: block;
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  text-transform: uppercase;
  color: #273272;
  border-radius: 500px;
}

.page-pagination li a.current {
  background: #f3525a;
  color: #ffffff;
}

.page-pagination li a:hover {
  color: #f3525a;
}

/*=============================================
=                - Preloader                =
=============================================*/
.preloader-active {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}

.preloader-active .preloader-area-wrap {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
}

.preloader-active .preloader-area-wrap .spinner div {
  background-color: #273272;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.preloader-active .preloader-area-wrap .spinner div.bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.preloader-active .preloader-area-wrap .spinner div.bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.preloader-area-wrap {
  display: none;
}

.open_tm_preloader {
  position: fixed;
  background-color: transparent;
  z-index: 9999;
  height: 100%;
  width: 100%;
  -webkit-transition: .2s all ease;
  -o-transition: .2s all ease;
  transition: .2s all ease;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.open_tm_preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.open_tm_preloader.loaded:before, .open_tm_preloader.loaded:after {
  width: 0%;
}

.open_tm_preloader:before, .open_tm_preloader:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}

.open_tm_preloader:before {
  top: 0;
  left: 0;
}

.open_tm_preloader:after {
  bottom: 0;
  right: 0;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #273272;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

.form-messege.error {
  color: red;
}

/*=====  End of Default CSS  ======*/
/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .section-space--ptb_140 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_140 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_140 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_140 {
  padding-top: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .section-space--pt_140 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_140 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_140 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .section-space--pb_120 {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.pb-25 {
  padding-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__10 {
    margin-top: 10px;
  }
  .tablet-mt__20 {
    margin-top: 20px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-pt__20 {
    padding-top: 20px;
  }
  .tablet-pb__20 {
    padding-bottom: 20px;
  }
  .tablet-pt__10 {
    padding-top: 10px;
  }
  .tablet-pb__10 {
    padding-bottom: 10px;
  }
  .tablet-pt__0 {
    padding-top: 0px;
  }
  .tablet-pb__0 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .small-pt__0 {
    padding-top: 0px;
  }
  .small-pb__0 {
    padding-bottom: 0px;
  }
  .small-pt__10 {
    padding-top: 10px;
  }
  .small-pb__10 {
    padding-bottom: 10px;
  }
  .small-pt__20 {
    padding-top: 20px;
  }
  .small-pb__20 {
    padding-bottom: 20px;
  }
}

/*=====  End of spacing  ======*/
/*===========================================
=        03. Custom Animation Css
===========================================*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.move-up.animate {
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-animation: moveVertical .65s ease forwards;
  animation: moveVertical .65s ease forwards;
}

.move-up-x {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.move-up-x.animate {
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-animation: moveHorizontal .65s ease forwards;
  animation: moveHorizontal .65s ease forwards;
}

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/
@-webkit-keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
}

@keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/
@-webkit-keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/
@-webkit-keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(40px);
    opacity: 0;
  }
}

@keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/
@-webkit-keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ------------------------
    Brook Hover Effect 
---------------------------*/
.brook-transition {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bk-hover a {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: #999;
}

.bk-hover a::after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.bk-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/* ------------------------
    Separator Bounce 
---------------------------*/
@-webkit-keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*=============================================
=            04. container Css            =
=============================================*/
@media (min-width: 1600px) {
  .container-fluid--cp-150 {
    padding: 0 150px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-150 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}

@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-100 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-100 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-100 {
    padding: 0 50px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-80 {
    padding: 0 80px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-80 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

.container-fluid--cp-60 {
  padding: 0 60px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}

.container-fluid--cp-30 {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

.container--1450 {
  max-width: 1450px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cou-container-device {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .cou-container-device {
    max-width: 540px;
  }
}

@media (min-width: 1200px) {
  .page-content-double-sidebar .container {
    max-width: 1600px;
  }
}

@media (max-width: 1919px) {
  .container-fluid--cp-60 {
    padding: 0 30px;
  }
  .container-fluid--cp-80 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 767px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 575px) {
  .container-fluid--cp-80 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
}

.col-05__right {
  width: 500px;
  max-width: 100%;
  margin-left: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-05__right {
    margin-left: 0px;
    margin: auto;
  }
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*=====  End of container  ======*/
/*=============================================
=              05. Header Css            =
=============================================*/
.header-top-wrap {
  position: relative;
}

.header-area .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li > a {
  color: #444;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li > a > span:after {
  background-color: #444;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li.active > a {
  color: #000000;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li:hover > a:after, .header-area .is-sticky .navigation-menu--text_white > ul > li.active > a:after {
  color: #000000;
}

.header-area .is-sticky .navigation-menu--text_white > ul > li:hover > a > span, .header-area .is-sticky .navigation-menu--text_white > ul > li.active > a > span {
  color: #000000;
}

.header-area .is-sticky .header-search-form .search-form-top .style-02 {
  background-color: transparent;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.header-area .is-sticky .header-search-form .search-form-top.style-03 {
  background-color: transparent;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.header-area .is-sticky .header-search-form .search-form-top.style-03 input::placeholder, .header-area .is-sticky .header-search-form .search-form-top.style-03 textarea::placeholder {
  color: #333;
}

.header-area .is-sticky .header-search-form .search-form-top .search-field {
  color: #000;
}

.header-area .is-sticky .header-search-form .search-form-top .search-submit {
  color: #333;
}

.header-area .is-sticky .header__logo .dark-logo {
  display: inherit;
}

.header-area .is-sticky .header__logo .light-logo {
  display: none;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li > a {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a:after, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a:after {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a > span, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a > span {
  color: #333;
}

.header-area .is-sticky .menu-style-four .navigation-menu > ul > li:hover > a:before, .header-area .is-sticky .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.header-area .is-sticky .header-search-form-two.white-icon a {
  color: #333;
}

.header-area .is-sticky.header-area--height {
  height: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area.is-sticky.only-mobile-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
    z-index: 999;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area.is-sticky.only-mobile-sticky .header-bottom-wrap.is-sticky {
    display: none !important;
  }
}

.header-area--absolute {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area--absolute {
    position: static;
  }
  .header-area--absolute .header-area--height {
    height: auto;
  }
}

.header-area--default {
  border-color: #E4E8F6;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.header-area--height {
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;
}

.header {
  display: flex;
  align-items: center;
  /* logo */
}

.header__logo {
  flex-basis: 20%;
  padding: 14px 0;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}

.header__logo.top-logo {
  padding: 25px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo {
    flex-basis: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    flex-basis: 50%;
  }
  .header__logo.top-logo {
    padding: 15px 0;
  }
}

.header__logo .dark-logo {
  display: none;
}

.header__logo .light-logo {
  display: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo .dark-logo {
    display: inherit;
  }
  .header__logo .light-logo {
    display: none;
  }
}

.header.default-menu-style .header__logo {
  flex-basis: 23.5%;
  justify-content: flex-start;
  padding: 14px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header.default-menu-style .header__logo {
    flex-basis: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header.default-menu-style .header__logo {
    flex-basis: 25%;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header__logo {
    flex-basis: 50%;
    justify-content: flex-start;
  }
}

.header.default-menu-style .header-midle-box {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.header.default-menu-style .header-right-box {
  flex-shrink: 0;
  flex-basis: 23.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 23%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header-right-box {
    flex-basis: 50%;
  }
}

.header.default-menu-style .header-right-box .header-right-inner {
  display: flex;
  margin: 0 -12px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .header.default-menu-style .header-right-box .header-right-inner {
    margin: auto;
  }
}

/*======================
    - language 
=======================*/
.language-menu > ul > li {
  position: relative;
  display: inline-block;
  line-height: 1;
}

.language-menu > ul > li > a {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .language-menu > ul > li > a {
    color: #333;
  }
}

.language-menu > ul > li > a span {
  margin-left: 5px;
}

.language-menu > ul > li ul {
  min-width: 100px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 102;
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
  transform: translateY(30px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.language-menu > ul > li ul li a {
  font-weight: 500;
  color: #333;
  display: block;
  text-decoration: none;
  padding: 10px;
  border: 0;
  background: #fff;
  color: #222;
  line-height: 1;
}

.language-menu > ul > li ul li a span {
  margin-left: 5px;
}

.language-menu > ul > li ul li a:hover {
  background: #EEEEEE;
}

.language-menu > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.header-right {
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header-right-inner {
  display: flex;
  margin: 0 -12px;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0 12px;
}

@media only screen and (max-width: 767px) {
  .header-right-inner {
    position: absolute;
    top: 100%;
    left: auto;
    right: 15px;
    margin-right: auto;
    width: 290px;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
    transform: translateY(30px);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .header-right-inner.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 999;
  }
}

@media only screen and (max-width: 575px) {
  .header-right-box .header-right-inner {
    flex-direction: column;
  }
}

.top-message {
  padding: 16px 0;
  font-size: 15px;
  line-height: 1.78;
  color: #ffffff;
}

.top-message a {
  font-weight: 500;
  color: #ffffff;
  font-size: 15px;
}

.top-message a:hover {
  color: #f3525a;
}

.mobile-top-message {
  padding: 0px 0;
  color: #333;
}

.mobile-top-message a {
  color: #333;
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
}

.navigation-menu > ul > li {
  margin: 0 33px;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navigation-menu > ul > li {
    margin: 0 22px;
  }
}

.navigation-menu > ul > li > a {
  display: block;
  color: #000000;
  padding: 21px 2px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
  transition: all 0.0s ease-in-out;
}

.navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #273272;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navigation-menu > ul > li > a span {
  transition: all 0.0s ease-in-out;
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #273272;
}

.navigation-menu > ul > li:hover > a span, .navigation-menu > ul > li.active > a span {
  color: #273272;
}

.navigation-menu > ul > li:hover > a:before, .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

.navigation-menu.primary--menu > ul > li > a {
  padding: 36px 2px;
}

.navigation-menu--text_white > ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.navigation-menu--text_white > ul > li > a:before {
  background-color: #ffffff;
}

.navigation-menu--text_white > ul > li.active > a {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a:after, .navigation-menu--text_white > ul > li.active > a:after {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a > span, .navigation-menu--text_white > ul > li.active > a > span {
  color: #ffffff;
}

.header-bottom-left-wrap .navigation-menu > ul > li:first-child {
  margin: 0 24px 0 0;
}

.header-bottom-left-wrap .navigation-menu > ul > li:last-child {
  margin: 0 0px 0 24px;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #273272;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 248px;
  padding: 20px 0;
  z-index: 9;
}

.submenu.left {
  right: 0;
  left: auto;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 5px 30px;
  color: #707070;
  font-weight: 400;
  transition: 0s;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #273272;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #273272;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #273272;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "FontAwesome";
  content: '\f105';
  font-size: 14px;
  vertical-align: middle;
  color: #ababab;
}

.submenu > li.has-children > a:hover:after {
  color: #273272;
}

.submenu > li.has-children.active > a {
  color: #273272;
}

.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.menu-style-three .navigation-menu > ul > li, .menu-style-four .navigation-menu > ul > li {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .menu-style-three .navigation-menu > ul > li, .menu-style-four .navigation-menu > ul > li {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.menu-style-three .navigation-menu > ul > li:hover > a:before, .menu-style-three .navigation-menu > ul > li.active > a:before, .menu-style-four .navigation-menu > ul > li:hover > a:before, .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 0%;
  left: 0;
}

.menu-style-three .submenu.left, .menu-style-four .submenu.left {
  left: -100px;
}

.menu-style-three .submenu.left .submenu.left, .menu-style-four .submenu.left .submenu.left {
  right: 100%;
  left: auto;
}

.menu-style-four .navigation-menu > ul > li > a {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #f3525a;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menu-style-four .navigation-menu > ul > li:hover > a:after, .menu-style-four .navigation-menu > ul > li.active > a:after {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li:hover > a > span, .menu-style-four .navigation-menu > ul > li.active > a > span {
  color: #ffffff;
}

.menu-style-four .navigation-menu > ul > li:hover > a:before, .menu-style-four .navigation-menu > ul > li.active > a:before {
  width: 100%;
  left: 0;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #273272;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.megamenu--mega {
  min-width: 980px;
  width: 100%;
  padding: 35px 20px 30px;
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    min-width: 700px;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #333333;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #ababab;
  line-height: 1.2;
  transition: 0.1s;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #273272;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #273272;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #273272;
}

.container-fluid .megamenu--mega {
  padding: 35px 220px 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid .megamenu--mega {
    min-width: 700px;
  }
}

/* Mobile Mavigation icon */
.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
}

.mobile-navigation-icon:hover i {
  background-color: #273272;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #273272;
}

.mobile-navigation-icon:hover i:after {
  background-color: #273272;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-navigation-icon.white-md-icon i {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:before {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:after {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon:hover i {
    background-color: #273272;
  }
  .mobile-navigation-icon.white-md-icon:hover i:before {
    width: 80%;
    background-color: #273272;
  }
  .mobile-navigation-icon.white-md-icon:hover i:after {
    background-color: #273272;
    width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .is-sticky .white-md-icon i {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:before {
    background-color: #333;
  }
  .is-sticky .white-md-icon i:after {
    background-color: #333;
  }
}

.page-open-off-sidebar:hover i {
  width: 100%;
}

.page-open-off-sidebar:hover i:before {
  width: 100%;
}

.page-open-off-sidebar:hover i:after {
  width: 100%;
}

.page-open-off-sidebar i {
  width: 100%;
  background-color: #333;
}

.page-open-off-sidebar i:before {
  width: 70%;
  background-color: #333;
}

.page-open-off-sidebar i:after {
  width: 70%;
  background-color: #333;
}

.hidden-icons-menu {
  margin-left: 15px;
  float: right;
}

.hidden-icons-menu a {
  color: #ffffff;
  font-size: 20px;
}

.hidden-icons-menu a:hover {
  color: #ddd;
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay, .page-oppen-off-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  overflow: auto;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
}

.mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
  transform: translateX(120%);
  width: 400px;
  height: 100%;
  float: right;
  cursor: default;
  background: #273272;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.mobile-menu-overlay.active, .page-oppen-off-sidebar.active {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-overlay.active .mobile-menu-overlay__inner, .page-oppen-off-sidebar.active .mobile-menu-overlay__inner {
  transform: translateX(0%);
}

.mobile-menu-overlay__header, .page-oppen-off-sidebar__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon, .page-oppen-off-sidebar__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover {
  color: #273272;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover:before, .mobile-menu-overlay__header .mobile-navigation-close-icon:hover:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.mobile-menu-overlay__body, .page-oppen-off-sidebar__body {
  padding: 20px 40px 100px;
}

.mobile-menu-overlay__body .offcanvas-navigation, .page-oppen-off-sidebar__body .offcanvas-navigation {
  /* onepage style */
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #ffffff;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: '\f106';
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 18px;
  font-family: "FontAwesome";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 14px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "FontAwesome";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "FontAwesome";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation--onepage > ul > li.active > a, .page-oppen-off-sidebar__body .offcanvas-navigation--onepage > ul > li.active > a {
  font-weight: 700;
}

/*======================================
    - offset search
======================================*/
.page-oppen-off-sidebar {
  right: 0;
}

.page-oppen-off-sidebar__inner {
  transform: translateX(100%);
  width: 400px;
  float: right;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.page-oppen-off-sidebar.active .page-oppen-off-sidebar__inner {
  transform: translateX(0%);
}

.page-oppen-off-sidebar__header {
  background: #ffffff;
  padding: 15px 35px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item {
  display: flex;
  margin-bottom: 12px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item .icon {
  color: #6d70a6;
  min-width: calc(1em + 18px);
  font-size: 16px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item a:hover {
  color: #38cb89;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li a {
  line-height: 17px;
}

.header__navigation.preview-menu {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header__navigation.preview-menu .navigation-menu-right {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.preview-header-inner {
  padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .preview-header-inner {
    padding: 0;
  }
}

.preview-header-inner.is-sticky {
  padding: 0px 0;
}

.preview-header-inner.is-sticky .header__navigation.preview-menu ul > li > a {
  color: #333;
}

.preview-header-inner.is-sticky .header__actions--preview .ht-btn {
  background: transparent;
  border: 1px solid #273272;
  color: #273272;
  line-height: 42px;
  height: 43px;
}

.preview-header-inner .header__actions--preview .ht-btn {
  background: #273272;
  border: 1px solid #273272;
  color: #ffffff;
}

.preview-header-inner .header__actions--preview .ht-btn:hover {
  background: #ffffff;
  color: #273272;
}

/*=====  End of Header  ======*/
/*=========================================
=               06. Footer Css            =
===========================================*/
.footer-bg--overlay {
  position: relative;
  z-index: 0;
}

.footer-bg--overlay::before {
  background-color: #1C2945;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.98;
  z-index: -1;
}

.coustom-row-in-footer {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .coustom-row-in-footer {
    flex-direction: column;
  }
}

.footer-widget-coustom-col {
  flex-basis: 50%;
  padding-left: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget-coustom-col {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget-coustom-col {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget-coustom-col {
    padding-left: 0px;
  }
}

.footer-widget-coustom-col:nth-child(2) {
  padding-left: 68px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget-coustom-col:nth-child(2) {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget-coustom-col:nth-child(2) {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget-coustom-col:nth-child(2) {
    padding-left: 0px;
  }
}

.footer-widget-dic p {
  color: #ffffff;
}

.footer-widget-dic p.footer-dec {
  max-width: 334px;
}

.footer-widget-dic h5 {
  font-weight: 600;
  color: #ffffff;
}

.newsletter-footer-wrap .widget-newsletter-form {
  display: flex;
  max-width: 342px;
  position: relative;
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

.newsletter-footer-wrap .widget-newsletter-form input:not([type="submit"]) {
  background: #505A6D;
  height: 45px;
  padding: 15px 130px 15px 15px;
  border: 1px solid #505A6D;
  width: 100%;
  color: #ffffff;
}

.newsletter-footer-wrap .widget-newsletter-form .subscribe-button {
  position: absolute;
  right: 0;
}

.newsletter-footer-wrap .widget-newsletter-form .subscribe-button .subscribe-btn {
  background: #C3C4C5;
  height: 45px;
  padding: 0 18px;
  border: none;
  color: #273272;
}

.newsletter-footer-wrap .widget-newsletter-form .subscribe-button .subscribe-btn:hover {
  color: #ffffff;
  background: #f3525a;
}

.newsletter-footer-wrap .widget-newsletter-form input:focus::-webkit-input-placeholder {
  color: #fff;
}

.newsletter-footer-wrap .widget-newsletter-form input:focus:-moz-placeholder {
  color: #fff;
}

.newsletter-footer-wrap .widget-newsletter-form input:focus::-moz-placeholder {
  color: #fff;
}

.newsletter-footer-wrap .widget-newsletter-form input:focus:-ms-input-placeholder {
  color: #fff;
}

.newsletter-footer-wrap .widget-newsletter-form input::placeholder, .newsletter-footer-wrap .widget-newsletter-form textarea::placeholder {
  color: #fff;
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget__title {
  margin-top: -3px;
  color: #333333;
}

.footer-widget__item-list {
  width: 50%;
  float: left;
}

.footer-widget__item-list li {
  margin-bottom: 10px;
  color: #ffffff;
}

.footer-widget__item-list li a {
  position: relative;
  padding-left: 20px;
}

.footer-widget__item-list li a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f101";
  font-family: "FontAwesome";
}

.footer-widget__item-list li:last-child {
  margin-bottom: 0;
}

.footer-widget__list .single-footer-widger {
  margin-bottom: 20px;
}

.footer-widget__list .single-footer-widger:last-child {
  margin-bottom: 0;
}

.footer-widget__list .single-footer-widger h5 {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 8px;
}

.footer-widget__list .single-footer-widger p {
  color: #ffffff;
}

.footer-widget__wrap {
  display: flex;
  justify-content: space-between;
}

.footer-widget__wrap .single-footer-widger {
  flex-basis: 33.333%;
  padding-left: 60px;
}

.footer-widget__wrap .single-footer-widger:first-child {
  padding-left: 30px;
}

.footer-widget__wrap .single-footer-widger h5 {
  font-weight: 600;
  margin-bottom: 8px;
}

.footer-widget__wrap .single-footer-widger p {
  font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget__wrap .single-footer-widger {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget__wrap .single-footer-widger {
    padding-left: 40px;
  }
  .footer-widget__wrap .single-footer-widger:first-child {
    padding-left: 15px;
  }
  .footer-widget__wrap .single-footer-widger:nth-child(2) {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget__wrap .single-footer-widger {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer-widget__wrap .single-footer-widger:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget__wrap {
    flex-direction: column;
  }
}

.copyright-text {
  display: inline-block;
}

/*=====  End of footer ======*/
/*=======================================
=            07. Sidebar Css           =
=========================================*/
.left-sidebar {
  padding-right: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .left-sidebar {
    padding-right: 0px;
  }
}

.right-sidebar {
  padding-left: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .right-sidebar {
    padding-left: 0px;
  }
}

.widget-title {
  color: #273272;
  margin-bottom: 32px;
}

/* Widget Categories */
.widget-categories ul li:not(:last-child) {
  margin-bottom: 14px;
}

.widget-categories ul li a {
  overflow: hidden;
  display: block;
  font-weight: 300;
}

.widget-categories ul li a .widget-count {
  background: #fff;
  padding: 0px 3px 0px 18px !important;
  float: right;
}

.widget-categories ul li a span {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.widget-categories ul li a span::after {
  content: "";
  border-top: 1px solid #273272;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -1px;
  height: 1px;
  width: 600px;
  transition: all 0.3s ease-in-out 0s;
}

.widget-categories ul li a:hover {
  color: #273272;
}

.widget-categories ul li a .widget-count {
  background: #f5f6ff;
}

.widget-categories.style-two ul li:not(:last-child) {
  margin-bottom: 14px;
}

.widget-categories.style-two ul li a {
  overflow: hidden;
  display: block;
  font-weight: 300;
  height: 44px;
  line-height: 44px;
  background: #fff;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
}

.widget-categories.style-two ul li a .widget-count {
  background: #dbdeed;
  padding: 0px !important;
  float: right;
  width: 60px;
  text-align: center;
}

.widget-categories.style-two ul li a span {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.widget-categories.style-two ul li a:hover {
  background: #273272;
  color: #ffffff;
}

.widget-categories.style-two ul li a:hover .widget-count {
  background: #f3525a;
  color: #ffffff;
}

.widget-box {
  background: #f5f6ff;
  padding: 30px;
}

/* Service Widget Categories */
.service-widget-title {
  color: #2b2b2b;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  padding: 20px 30px;
  border-bottom: 1px solid #ddd;
}

.service-widget-title span {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.service-widget-title span::after {
  content: "";
  border-top: 1px dashed #8d98d8;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -1px;
  height: 1px;
  width: 110px;
  transition: all 0.3s ease-in-out 0s;
}

/* Widget Search */
.widget-search-form {
  display: flex;
  border-radius: 5px;
  position: relative;
}

.widget-search-form input:not([type="submit"]) {
  color: #273272;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  padding: 0 40px 0 0;
  width: 100%;
  height: 45px;
  font-size: 15px;
  font-weight: 400;
}

.widget-search-form button {
  background: transparent;
  border: none;
  line-height: 43px;
  height: 45px;
  padding: 0;
  color: #273272;
  font-size: 20px;
  position: absolute;
  right: 0;
}

/* Widget Latestblog */
.widget-latestblog {
  position: relative;
}

.widget-latestblog ul li {
  position: relative;
  padding-left: 100px;
  min-height: 80px;
}

.widget-latestblog ul li:not(:last-child) {
  margin-bottom: 40px;
}

.widget-latestblog ul li .widget-latestblog-image {
  position: absolute;
  left: 0;
  top: 5px;
  overflow: hidden;
  width: 100px;
}

.widget-latestblog ul li span {
  font-size: 14px;
  display: inline-block;
}

.widget-latestblog ul li h5 {
  margin-bottom: 5px;
  font-size: 16px;
}

.widget-latestblog ul li h5 a {
  color: #273272;
}

.widget-latestblog ul li h5 a:hover {
  color: #273272;
}

.widget-latestblog ul li p {
  font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-latestblog ul li {
    padding-left: 95px;
  }
  .widget-latestblog ul li .widget-latestblog-image {
    width: 80px;
  }
}

.widget-form-item select {
  padding: 3px 0px;
  height: 46px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #273272;
  border-radius: 0px;
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px;
  background-color: transparent;
}

.widget-form-item select:focus {
  background: transparent url("../images/icons/selector-icon.png") no-repeat center right 5px !important;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #273272;
}

.widget-tag-list a {
  margin-right: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.widget-tag-list a:hover {
  color: #f3525a;
}

.widget-banner-box {
  text-align: center;
  background: #273272;
  padding: 45px 15px 50px;
}

.widget-banner-box h3 {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 25px;
}

.widget-banner-box h4 {
  color: #ffffff;
  font-size: 20px;
  margin-top: 25px;
  font-weight: 300;
}

.ui-widget-content {
  background: #f3525a;
  border: medium none;
  border-radius: 0px;
  height: 6px;
  margin: 35px 0 25px;
}

.ui-widget-content .ui-state-default {
  background: #273272;
  border: 3px solid #273272;
  border-radius: 25px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  font-weight: normal;
  height: 15px;
  width: 15px;
}

.ui-widget-content .ui-state-default:hover {
  background: #273272;
}

.ui-widget-content .ui-slider-range {
  background: #273272;
}

.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.filter-price-cont {
  font-size: 14px;
}

.filter-price-cont .input-type input {
  border: medium none;
  padding: 0 8px 0 0;
  text-align: center;
  width: 40px;
  background: transparent;
}

.filter-price-cont .input-type:last-child {
  padding-left: 8px;
}

/*=====  End of Sidebar  ======*/
/*=======================================
=          08. Product style Css          =
=========================================*/
.single-product-wrap .product-thumbnail {
  position: relative;
  overflow: hidden;
}

.single-product-wrap .product-thumbnail a {
  display: block;
}

.single-product-wrap .product-thumbnail a img {
  width: 100%;
}

.single-product-wrap .product-hover-image {
  transition: all .6s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
}

.single-product-wrap .product-thumbnail-box img {
  transition: all .6s;
  width: 100%;
}

.single-product-wrap .product-badges {
  position: absolute;
  top: 10px;
  right: 8px;
  z-index: 99;
  display: flex;
}

.single-product-wrap .product-badges > span {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin: 0 2px;
  padding: 6px 6px 4px;
  min-height: 0;
  min-width: 56px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.single-product-wrap .product-badges .hot {
  color: #fff;
  background-color: #d3122a;
}

.single-product-wrap .product-badges .onsale {
  color: #fff;
  background-color: #f6b500;
}

.single-product-wrap .product-actions {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  color: #fff;
  padding-right: 8px;
  background: #333;
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-product-wrap .product-actions .p-action-btn {
  padding: 0px 8px;
  color: #ffffff;
}

.single-product-wrap:hover .product-actions {
  transform: none;
  visibility: visible;
  opacity: 1;
}

.single-product-wrap:hover .product-thumb-image img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.single-product-wrap:hover .product-hover-image {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.single-product-wrap.list-style {
  display: flex;
  border-bottom: 1px solid #e4e8f6;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.single-product-wrap.list-style .product-thumbnail {
  position: relative;
  overflow: hidden;
  width: 260px;
  flex-shrink: 0;
}

.single-product-wrap.list-style .product-info {
  flex-grow: 1;
  padding-left: 34px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap.list-style {
    flex-direction: column;
  }
  .single-product-wrap.list-style .product-thumbnail {
    width: 100%;
  }
  .single-product-wrap.list-style .product-info {
    padding-left: 0px;
  }
}

.single-product-wrap .product-cart-btn {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
  color: #fff;
  background: none;
  transform: none;
  line-height: 56px;
  height: 56px;
  font-weight: 500;
}

.single-product-wrap .product-cart-btn::before {
  content: '\f290' !important;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 19px;
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  line-height: 1;
  font-family: "FontAwesome";
  font-weight: 400;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-product-wrap .product-cart-btn:hover {
  padding-left: 35px;
  color: #ffffff;
}

.single-product-wrap .product-cart-btn:hover::before {
  left: 15px;
  visibility: visible;
  opacity: 1;
  color: #ffffff;
}

.single-product-wrap .product-info {
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.15);
}

.single-product-wrap .product-info .product__title {
  font-weight: 600;
  font-size: 18px;
  color: #273272;
}

.single-product-wrap .product-info .price-box {
  margin-top: 5px;
}

.single-product-wrap .product-info .price-box .old-price {
  font-size: .88em;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.single-product-wrap .product-info .price-box .new-price {
  font-size: 18px;
  font-weight: 500;
  color: #f3525a;
}

.product-details-thumbs-2 {
  float: left;
  width: 170px;
}

.product-details-thumbs-2 .sm-image {
  margin-bottom: 28px;
}

.product-details-thumbs-2 .sm-image:last-child {
  margin-bottom: 0;
}

.product-details-thumbs-2 .sm-image img {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .product-details-thumbs-2 {
    width: 80px;
  }
}

.product-details-images-2 {
  float: right;
  width: calc(100% - 170px);
  padding-left: 28px;
}

.product-details-images-2 .lg-image a {
  display: block;
}

.product-details-images-2 .lg-image a img {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .product-details-images-2 {
    width: calc(100% - 80px);
    padding-left: 18px;
  }
}

.product-details-thumbs-2 .slick-arrow {
  font-size: 20px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  bottom: auto;
  background: #ddd;
  border: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.product-details-thumbs-2 .slick-arrow:hover {
  background: #273272;
  color: #ffffff;
}

.product-details-thumbs-2 .slick-arrow.slick-next {
  bottom: 0px;
  top: auto;
}

.product-details-thumbs-2:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}

.totle-review-star-box {
  display: flex;
  margin-top: 5px;
}

.totle-review-star-box .totle-review-star {
  margin-right: 20px;
}

.totle-review-star-box .totle-review-star a {
  color: #f3525a;
}

.product-details-view-content .price-box {
  margin-top: 10px;
}

.product-details-view-content .price-box .old-price {
  font-size: 18px;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
  margin-right: 20px;
}

.product-details-view-content .price-box .new-price {
  font-size: 18px;
  font-weight: 600;
  color: #f3525a;
}

.attend-check-box-area {
  margin-top: 30px;
}

.leble-title {
  margin-bottom: 15px;
  color: #273272;
}

.check-box {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  line-height: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #444;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.check-box:hover input ~ .checkmark {
  background-color: #f3525a;
  border: 1px solid #f3525a;
}

/* When the checkbox is checked, add a blue background */
.check-box input:checked ~ .checkmark {
  background-color: #f3525a;
  border: 1px solid #f3525a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-box input:checked ~ .checkmark:after {
  display: block;
}

.check-box-wrap .check-box {
  margin-right: 15px;
}

/* ==========================
    quantity
============================== */
.cart-quantity {
  display: flex;
  align-items: flex-end;
}

.cart-quantity .add-to-cart {
  border: none;
  font-size: 14px;
  color: #ffff;
  position: relative;
  background: #323232;
  padding-left: 13px;
  padding-bottom: 3px;
  cursor: pointer;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 30px;
  border-radius: 2px;
}

.cart-quantity .add-to-cart:hover {
  background: #273272;
}

.cart-plus-minus {
  margin-right: 25px;
  position: relative;
  width: 100px;
  text-align: center;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.cart-plus-minus .cart-plus-minus-box {
  border: 1px solid #fff;
  color: #333;
  height: 23px;
  text-align: center;
  width: 48px;
  width: 3rem;
}

.cart-plus-minus .dec.qtybutton,
.cart-plus-minus .inc.qtybutton {
  background: #ddd;
  color: #333333;
  cursor: pointer;
  height: 23px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 24px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  font-style: normal;
}

.cart-plus-minus .dec.qtybutton:hover,
.cart-plus-minus .inc.qtybutton:hover {
  background: #ddd;
}

.cart-plus-minus .dec.qtybutton {
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  border-radius: 0px 15px 15px 0;
}

.cart-plus-minus .inc.qtybutton {
  left: 0;
  top: 50%;
  line-height: 23px;
  font-family: normal;
  transform: translateY(-50%);
  right: auto;
  margin-left: 4px;
  border-radius: 15px 0px 0px 15px;
}

.single-product-share .meta-content a {
  margin-left: 20px;
}

.product-details-tab .nav li {
  margin-right: 30px;
}

.product-details-tab .nav li a {
  font-weight: 500;
  font-size: 18px;
  display: block;
  position: relative;
  transition: 0.3s ease-in-out;
}

.product-details-tab .nav li a::before {
  content: "";
  background: #273272;
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-details-tab .nav li a.active {
  color: #273272;
}

.product-details-tab .nav li a.active::before {
  visibility: visible;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .product-details-tab .nav li a {
    padding-bottom: 10px;
  }
  .product-details-tab .nav li a:last-child {
    margin-top: 10px;
  }
}

.product-details-tab .nav li:hover > a::before {
  visibility: visible;
  width: 100%;
}

/*=======================================
=          09. Cart Page Css         =
=========================================*/
.table-content table {
  background: #ffffff none repeat scroll 0 0;
  border-color: #ebebeb;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  text-align: center;
}

.table-content table td {
  border-top: medium none;
  font-size: 16px;
  padding: 20px 10px;
  vertical-align: middle;
  min-width: 140px;
}

.table-content th,
.table-content td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.plantmore-product-quantity > input {
  text-align: center;
  width: 48px;
}

.cart-table .coupon-all {
  margin-top: 50px;
}

.cart-table .coupon-all .coupon h3 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -8px;
}

.cart-table .coupon-all .coupon input {
  border: 1px solid #999999;
  font-size: 14px;
  margin-right: 10px;
  padding: 4px 10px;
  width: 130px;
}

.cart-table .coupon-all .coupon input.button {
  background: #000000;
  border: 0 none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  padding: 0 14px;
  text-transform: uppercase;
  width: inherit;
}

.cart-table .coupon-all .coupon input.button:hover {
  background: #273272;
}

@media only screen and (max-width: 575px) {
  .cart-table .coupon-all .coupon input {
    width: 125px;
  }
}

.cart-table .coupon-all .coupon2 {
  margin-bottom: 40px;
}

.cart-table .coupon-all .coupon2 input {
  margin-right: 8px;
}

.cart-table .coupon-all .coupon2 input.submit {
  background: #000000;
}

.cart-table .coupon-all .coupon2 input.submit:hover {
  background: #273272;
  color: #ffffff;
}

.cart-table .coupon-all .coupon2 .continue-btn {
  background: #000000;
}

.cart-table .coupon-all .coupon2 .continue-btn:hover {
  background: #273272;
  color: #ffffff;
}

.cart-page-total {
  margin-top: 40px;
}

.cart-page-total h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.cart-page-total ul {
  border: 1px solid #dddddd;
}

.cart-page-total ul li {
  border-bottom: 1px solid #ebebeb;
  color: #252525;
  font-size: 15px;
  font-weight: 700;
  list-style: outside none none;
  padding: 10px 30px;
}

.cart-page-total ul li:last-child {
  border: none;
}

.cart-page-total ul li span {
  float: right;
}

.cart-page-total .proceed-checkout-btn {
  background: #000000;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 8px 16px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
}

.cart-page-total .proceed-checkout-btn:hover {
  background: #273272;
}

.plantmore-product-add-cart > a {
  background: #333;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 30px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
}

.plantmore-product-add-cart > a:hover {
  background: #273272;
}

.in-stock {
  color: #30b878;
}

.out-stock {
  color: #A80135;
}

.table-hover tbody tr:hover {
  background-color: #fafafa;
}

/*=======================================
=          10. Checkout Page Css        =
=========================================*/
.coupon-accordion h3 {
  background: #f6f6f6;
  border-top: 3px solid #273272;
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 30px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  text-transform: capitalize;
}

.coupon-accordion h3 .coupon {
  color: #273272;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.coupon-accordion h3 .coupon:hover {
  color: #000000;
}

.coupon-content {
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  padding: 20px;
  display: none;
}

.coupon-info p.form-row-first {
  float: left;
  width: 48%;
}

@media only screen and (max-width: 575px) {
  .coupon-info p.form-row-first {
    width: 100%;
  }
}

.coupon-info p.form-row-last {
  float: right;
  width: 48%;
}

@media only screen and (max-width: 575px) {
  .coupon-info p.form-row-last {
    width: 100%;
  }
}

.coupon-info .remember {
  margin-left: 10px;
}

.coupon-info .remember span {
  margin-left: 5px;
}

.coupon-input label {
  display: block;
  font-size: 14px;
}

.coupon-input input {
  border: 1px solid #999999;
  color: #000000;
  padding: 5px 10px;
  width: 100%;
  font-size: 14px;
}

.coupon-input span.required {
  color: red;
}

.checkout-coupon input {
  border: 1px solid #999999;
  color: #555;
  padding: 5px 10px;
  width: auto;
}

.checkout-coupon input:focus {
  outline: none;
}

.checkout-coupon .button-apply-coupon {
  margin: -5px 0 0 10px;
  padding: 7.2px 11px;
}

@media only screen and (max-width: 575px) {
  .checkout-coupon .button-apply-coupon {
    margin: 10px 0 0 0px;
  }
}

.shoping-checkboxt-title {
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.single-form-row {
  margin-bottom: 20px !important;
}

.single-form-row label {
  font-size: 14px;
  margin-bottom: 2px;
}

.single-form-row label span.required {
  color: red;
}

.single-form-row input {
  border: 1px solid #999999;
  color: #666;
  font-size: 14px;
  padding: 5px 12px;
  width: 100%;
}

.single-form-row input::focus {
  outline: none;
}

.single-form-row textarea {
  border: 1px solid #999999;
  color: #555555;
  padding: 12px;
  width: 100%;
  font-size: 14px;
}

.single-form-row.m-0 {
  margin: 0 !important;
}

.checkout-box-wrap p {
  font-size: 14px;
}

.checkout-box-wrap .ship-box-info {
  display: none;
}

.account-create {
  display: none;
}

.account-create .creat-pass > span {
  color: red;
}

.nice-select select {
  height: 35px;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  color: #555;
  border: 1px solid #999;
  border-radius: 0px;
}

@media only screen and (max-width: 767px) {
  .your-order-wrapper {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .your-order-wrapper {
    margin-top: 60px;
  }
}

.your-order-wrap {
  background: #f6f6f6;
}

.your-order-table {
  padding: 20px 30px;
}

.your-order-table table {
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #d8d8d8;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order-table table th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.your-order-table table .shipping > th {
  vertical-align: top;
}

.payment-method {
  padding: 20px 30px;
}

.payment-accordion h3 a {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  padding-left: 31px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
}

.payment-accordion h3 a::before, .payment-accordion h3 a::after {
  content: "\f067";
  font-family: "FontAwesome";
  display: inline-block;
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 0px;
}

.payment-accordion h3 a img {
  height: 60px;
  display: block;
}

.payment-accordion h3.open a::after {
  content: "\f068";
}

.payment-accordion p {
  font-size: 14px;
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.order-button-payment {
  margin-top: 30px;
}

.order-button-payment input {
  background: #273272;
  border: medium none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.order-button-payment input:hover {
  background: #000000;
  color: #ffffff;
}

/*@import 'elements';*/
/*=============================================
=            11. Services Css               =
=============================================*/
.service-custom-row {
  display: flex;
  justify-content: flex-end;
  padding-left: 105px;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-custom-row {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-custom-row {
    flex-direction: column;
    max-width: 720px;
    width: 100%;
    padding-left: 0px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .service-custom-row {
    flex-direction: column;
    max-width: 540px;
    width: 100%;
    padding-left: 0px;
    margin: 0 auto;
  }
}

.service-col-01 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  flex-basis: 22%;
  display: flex;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-col-01 {
    flex-basis: 30%;
  }
}

.service-col-02 {
  max-width: 1000px;
  flex-grow: 2;
  flex-basis: 70%;
  padding-left: 55px;
  padding-right: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-col-02 {
    flex-basis: 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-col-02 {
    padding-left: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .service-col-02 {
    padding-left: 15px;
  }
}

.service-col-03 {
  flex-grow: 3;
  flex-basis: 8%;
  align-self: stretch;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-col-03 {
    display: none;
  }
}

.service-area-right-image {
  height: 100%;
  width: auto;
  background-repeat: no-repeat;
  background-position: right;
  background-color: transparent;
}

.service-area-right-image-two {
  height: 100%;
  width: auto;
  background-repeat: no-repeat;
  background-position: left;
  margin-top: 55px;
  background-color: transparent;
}

.ht-service-icon.style-01 {
  margin-bottom: 60px;
}

.ht-service-icon.style-01 .service-icon {
  margin-bottom: 28px;
}

.ht-service-icon.style-01 .service-icon svg > * {
  stroke: #273272;
}

.ht-service-icon.style-01 .service-icon .svg-icon {
  width: 65px;
  height: 65px;
}

.ht-service-icon.style-01 .service-content p {
  font-weight: 300;
  max-width: 300px;
}

.ht-service-box.style-2 {
  padding: 50px 40px;
  background: #f1f1f1;
  border-radius: 10px 70px 10px 30px;
}

.ht-service-box.style-2 p {
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .ht-service-box.style-2 h3 br {
    display: none;
  }
}

.ht-service-box.style-04 {
  margin-bottom: 30px;
  background: #fbfbfb;
  padding: 50px 32px;
  transition: all 0.3s ease-in-out;
}

.ht-service-box.style-04 .service-icon {
  margin-bottom: 28px;
}

.ht-service-box.style-04 .service-icon svg > * {
  stroke: #273272;
}

.ht-service-box.style-04 .service-icon .svg-icon {
  width: 65px;
  height: 65px;
}

.ht-service-box.style-04 .service-content p {
  font-weight: 300;
  max-width: 300px;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .ht-service-box.style-04 .service-content p {
    margin: 0 0;
    max-width: 100%;
  }
}

.ht-service-box.style-04:hover {
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

.ht-service-box.style-05 {
  margin-bottom: 30px;
  background: #fff;
  padding: 45px 15px;
  transition: all 0.3s ease-in-out;
}

.ht-service-box.style-05 .service-icon {
  margin-bottom: 28px;
}

.ht-service-box.style-05 .service-content {
  max-width: 215px;
  margin: auto;
}

.ht-service-box.style-05 .service-content p {
  font-weight: 300;
}

.ht-service-box.style-05.active {
  background: #f8f5ff;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

.ht-service-box.style-05:hover {
  background: #f8f5ff;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

.ht-service-box.style-06 {
  margin-bottom: 30px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  display: flex;
}

.ht-service-box.style-06 .service-icon {
  background: #f4effe;
  min-height: 150px;
  min-width: 150px;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  margin: auto;
  z-index: 1;
}

.ht-service-box.style-06 .service-icon img {
  width: 90px;
  height: 90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .ht-service-box.style-06 .service-icon {
    min-height: 130px;
    min-width: 130px;
    line-height: 130px;
  }
}

.ht-service-box.style-06 .service-content {
  background: #f8f5ff;
  padding: 45px 35px 45px 120px;
  max-width: 415px;
  margin-left: -100px;
}

.ht-service-box.style-06 .service-content p {
  font-weight: 300;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .ht-service-box.style-06 .service-content {
    padding: 35px 25px 35px 110px;
  }
}

.services-hight-450 {
  height: 450px;
}

.service-content-inner-bottom {
  margin-top: -180px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-content-inner-bottom {
    margin-top: -200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-content-inner-bottom {
    margin-top: -260px;
  }
}

@media only screen and (max-width: 767px) {
  .service-content-inner-bottom {
    margin-top: -300px;
  }
}

.ht-box-images.style-03 {
  box-shadow: 0 0 41px rgba(0, 0, 0, 0.03);
  background: #fff;
  padding: 50px 30px 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ht-box-images.style-03 .image-box-wrap {
  max-width: 360px;
  margin: 0 auto;
}

.ht-box-images.style-03 .image-box-wrap .box-image {
  margin-bottom: 20px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-03 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 0;
}

.ht-box-images.style-03 .image-box-wrap .content .text {
  color: #172541;
}

.ht-box-images.style-03:hover .box-image {
  transform: translateY(-5px);
}

.ht-box-images.style-03:hover .content .heading {
  color: #172541;
}

.ht-box-images.style-04 {
  margin-bottom: 60px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-box-images.style-04 {
    margin-bottom: 30px;
  }
  .ht-box-images.style-04 h4 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .ht-box-images.style-04 {
    margin-bottom: 0px;
    margin-top: 40px;
  }
}

.ht-box-images.style-04 .image-box-wrap {
  max-width: 360px;
  margin: 0 auto;
}

.ht-box-images.style-04 .image-box-wrap .box-image {
  margin-bottom: 20px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-04 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 0;
}

.ht-box-images.style-04 .image-box-wrap .content .text {
  color: #172541;
}

.ht-box-images.style-04:hover .box-image {
  transform: translateY(-5px);
}

.ht-box-images.style-04:hover .content .heading {
  color: #172541;
}

.ht-box-images.style-07 {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-images.style-07 .image-box-wrap {
  padding: 30px 35px 31px;
  border-radius: 4px;
  margin-top: 10px;
}

.ht-box-images.style-07 .image-box-wrap .box-image {
  text-align: center;
  margin-bottom: 24px;
}

.ht-box-images.style-07 .image-box-wrap .content {
  text-align: center;
}

.ht-box-images.style-07 .image-box-wrap .content .heading {
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 12px;
}

.ht-box-images.style-07 .image-box-wrap .content .text {
  color: #172541;
}

.ht-box-images.style-07:hover {
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
}

/*=============================================
=            12. Services Css               =
=============================================*/
.ht-btn {
  background: #273272;
  line-height: 1;
  color: #ffffff;
  transform: translateY(0px);
}

.ht-btn-lg {
  font-size: 24px;
  padding: 24px 28px;
}

.ht-btn-md {
  font-size: 18px;
  padding: 24px 20px;
}

.ht-btn-sm {
  padding: 20px 25px;
  font-size: 15px;
}

.ht-btn:hover, .ht-btn:active, .ht-btn:focus {
  color: #ffffff;
  background: #f3525a;
  transform: translateY(-3px);
}

.ht-btn.btn--white {
  background: #ffffff;
  color: #333333;
  padding: 20px 30px;
}

.ht-btn.btn--white:hover {
  background: #273272;
  color: #ffffff;
}

.btn {
  font-size: 15px;
  font-weight: 500;
  padding: 0 25px;
  transition: all 0.4s ease-in-out;
  height: 54px;
  line-height: 54px;
  border-radius: 0;
  color: #ffffff;
}

.btn__secondary {
  background-color: #f3525a;
}

.btn__secondary:hover {
  transform: translateY(-3px);
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .hero-button .ht-btn-lg {
    font-size: 20px;
    padding: 16px 22px;
  }
}

.ht-btn-icon {
  padding: 8px 83px 8px 25px;
  background: #273272;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 3;
}

.ht-btn-icon::before {
  font-family: "FontAwesome";
  content: "\f105";
  left: auto;
  right: 3px;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  width: 40px;
  color: #ffffff;
}

.ht-btn-icon::after {
  width: 30%;
  height: 200%;
  background: #f3525a;
  z-index: 1;
  right: 0;
  top: 0;
  margin: -5px 0 0 -5px;
  transform-origin: 0 0;
  position: absolute;
  content: '';
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-btn-icon:hover {
  color: #ffffff;
}

.ht-btn-icon:hover::after {
  transform: rotate(-20deg);
}

.ht-btn-icon-two {
  padding: 8px 83px 8px 25px;
  background: #f3525a;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 3;
}

.ht-btn-icon-two::before {
  font-family: "FontAwesome";
  content: "\f105";
  left: auto;
  right: 3px;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  width: 40px;
  color: #ffffff;
}

.ht-btn-icon-two::after {
  width: 30%;
  height: 200%;
  background: #273272;
  z-index: 1;
  right: 0;
  top: 0;
  margin: -5px 0 0 -5px;
  transform-origin: 0 0;
  position: absolute;
  content: '';
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-btn-icon-two:hover {
  color: #ffffff;
}

.ht-btn-icon-two:hover::after {
  transform: rotate(-20deg);
}

.purchase-btn, .btn-view-demo {
  background: #f3525a;
  line-height: 1;
  color: #ffffff;
  padding: 18px 30px;
}

.purchase-btn:hover, .btn-view-demo:hover {
  color: #ffffff;
  background: #333;
}

/*======================================
=         13. Counters Css             =
=======================================*/
.fun-fact-style-one {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fun-fact-style-one .single-fun-fact {
  padding: 0 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .fun-fact-style-one {
    justify-content: flex-start;
  }
  .fun-fact-style-one .single-fun-fact {
    width: 33.33%;
  }
}

@media only screen and (max-width: 575px) {
  .fun-fact-style-one {
    justify-content: center;
    text-align: center;
  }
  .fun-fact-style-one .single-fun-fact {
    width: 100%;
  }
}

.fun-fact-style-two {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}

.fun-fact-style-two .single-fun-fact {
  padding: 0 15px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fun-fact-style-two {
    justify-content: center;
  }
  .fun-fact-style-two .single-fun-fact {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .fun-fact-style-two {
    justify-content: center;
  }
  .fun-fact-style-two .single-fun-fact {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .fun-fact-style-two {
    justify-content: center;
    text-align: center;
  }
  .fun-fact-style-two .single-fun-fact {
    width: 100%;
  }
}

.fun-fact--one .fun-fact__count {
  color: #273272;
}

.fun-fact--one .fun-fact__text {
  font-size: 15px;
  font-weight: 600;
  color: #273272;
}

.fun-fact--two {
  margin-top: 40px;
}

.fun-fact--two .fun-fact__count {
  line-height: 1;
  font-size: 32px;
  color: #273272;
}

.fun-fact--two .fun-fact__text {
  font-size: 15px;
  font-weight: 600;
  color: #273272;
}

/*======================================
=            14. Listing Css           =
=======================================*/
.choose-us-content {
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .choose-us-content {
    padding-left: 0;
  }
}

.choose-us-content_left {
  padding-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .choose-us-content_left {
    padding-right: 0;
  }
}

.choose-itme-wrap {
  max-width: 458px;
}

.single-choose-item {
  display: flex;
  margin-top: 27px;
}

.single-choose-item .choose-item-images {
  width: 80px;
}

.single-choose-item .choose-icon-small {
  width: 40px;
}

.single-choose-item .choose-item-text {
  padding-left: 12px;
}

.single-choose-item .choose-item-text h4 {
  font-weight: 500;
  margin-bottom: 10px;
}

.single-choose-item .choose-item-text p {
  font-weight: 300;
}

.service-details-choose-itme-wrap .choose-item-text {
  max-width: 310px;
}

/*=============================================
=           15. Call To Action Css             =
=============================================*/
.cta-bg-image_one {
  background: url(../images/bg/call-to-action-01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cta-image-box {
  padding: 40px 60px;
}

@media only screen and (max-width: 575px) {
  .cta-image-box {
    padding: 40px 10px;
  }
  .cta-image-box br {
    display: none;
  }
}

/*=============================================
=         16. Testimonial slider Css           =
=============================================*/
.testimonial-slider.extra_m_lrtp {
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -80px;
  margin-bottom: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .testimonial-slider.extra_m_lrtp {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider.extra_m_lrtp {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-slider.extra_m_lrtp {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.testimonial-slider__container-one {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .testimonial-slider__container-one {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider__container-one {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-slider__container-one {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 100px;
  }
}

.testimonial-slider__container-one {
  cursor: w-resize;
}

.testimonial-slider__container-one .swiper-slide.swiper-slide-prev {
  opacity: 0.43;
}

.testimonial-slider__container-one .swiper-slide.swiper-slide-next {
  opacity: 0.43;
}

.testimonial-slider__container-one .swiper-slide.swiper-slide-active {
  transform: scale(1.22, 1.22);
}

.testimonial-slider__container-one .swiper-slide.swiper-slide-active .testimonial-slider--box {
  padding: 40px 20px;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

@media only screen and (max-width: 767px) {
  .testimonial-slider__container-one .swiper-slide.swiper-slide-active {
    transform: scale(1, 1.08);
  }
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box {
  padding: 34px;
  text-align: center;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
  font-size: 13px;
  margin: 0px 0;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .reting-star {
  font-size: 15px;
  margin-bottom: 5px;
  color: #f3525a;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .reting-star a {
  margin: 0 2px;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .testimonial-slider__text {
  font-weight: 300;
  line-height: 1.8;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .testimonial-slider__text .start-block {
  font-size: 30px;
  display: inline-block;
  height: 30px;
  bottom: -12px;
  line-height: 30px;
  position: relative;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .testimonial-slider__text .end-block {
  font-size: 30px;
  display: inline-block;
  height: auto;
  top: 20px;
  line-height: 0.3;
  position: relative;
}

.testimonial-slider__container-one .swiper-slide .testimonial-slider--box .testimonial-slider__author .name {
  font-weight: 500;
  color: #273272;
  margin-top: 10px;
}

.testimonial-slider__container-two .testimonial-slider--box {
  max-width: 420px;
}

.testimonial-slider__container-two .testimonial-slider--box .testimonial-slider__text {
  font-weight: 300;
  line-height: 1.8;
  padding-left: 20px;
}

.testimonial-slider__container-two .testimonial-slider--box .testimonial-slider__text .start-block {
  font-size: 30px;
  display: inline-block;
  height: 30px;
  bottom: -8px;
  line-height: 30px;
  position: relative;
  margin-left: -20px;
}

.testimonial-slider__container-two .testimonial-slider--box .testimonial-slider__text .end-block {
  font-size: 30px;
  display: inline-block;
  height: auto;
  top: 20px;
  line-height: 0.3;
  position: relative;
}

.testimonial-slider__container-two .testimonial-slider--box .testimonial-slider__author {
  margin-top: 20px;
  font-size: 15px;
  padding-left: 20px;
}

.testimonial-slider__container-two .testimonial-slider--box .testimonial-slider__author h6 {
  color: #273272;
}

.testimonial-slider__container-two .swiper-nav-button {
  background-image: none;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  width: 13px;
  height: 18px;
  bottom: 0;
  display: inline-block;
  position: relative;
  line-height: 18px;
  left: 0;
  margin-top: 20px;
  opacity: 0.6;
}

.testimonial-slider__container-two .swiper-nav-button:hover {
  opacity: 1;
}

.testimonials-vertical-wrap {
  height: 480px;
  max-width: 500px;
  margin: auto;
}

@media only screen and (max-width: 575px) {
  .testimonials-vertical-wrap {
    height: 780px;
  }
}

.testimonials-vertical-wrap .vertical-slider__container {
  height: 100%;
}

.testimonials-vertical-wrap .testimonial-slider-box {
  display: flex;
  align-items: center;
  padding: 30px;
  background: #F7F7F7;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .testimonials-vertical-wrap .testimonial-slider-box {
    flex-direction: column;
  }
}

.testimonials-vertical-wrap .testimonial-slider-box:hover {
  box-shadow: 0px 0px 4px 0px rgba(39, 50, 114, 0.14);
}

.testimonials-vertical-wrap .testimonial-slider-box .testimonial-images {
  min-width: 94px;
  margin-right: 20px;
}

.testimonials-vertical-wrap .testimonial-slider-box {
  padding: 30px 25px 30px;
}

.testimonials-vertical-wrap .testimonial-slider-box .testimonial--box p {
  font-weight: 300;
}

.testimonials-vertical-wrap .testimonial-slider-box .testimonial-slider__author {
  margin-top: 15px;
  text-align: right;
  font-size: 18px;
}

.testimonials-vertical-wrap .testimonial-slider-box .testimonial-slider__author h6 {
  color: #273272;
}

.testimonials-vertical-wrap .swiper-pagination-bullets {
  float: right;
  top: 50%;
  position: absolute;
  right: 40px;
  transform: translateY(-50%);
}

.testimonials-vertical-wrap .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 100%;
  background: #000;
  margin: 10px;
}

.testimonials-vertical-wrap .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonials-vertical-wrap .swiper-pagination-bullets {
    right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials-vertical-wrap .swiper-pagination-bullets {
    display: none;
  }
}

.testimonial-slider__container-three .testimonial-slider__single {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .testimonial-slider__container-three .testimonial-slider__single {
    flex-direction: column;
  }
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__text {
  font-weight: 300;
  line-height: 1.8;
  padding-left: 20px;
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__text .start-block {
  font-size: 30px;
  display: inline-block;
  height: 30px;
  bottom: -8px;
  line-height: 30px;
  position: relative;
  margin-left: -20px;
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__text .end-block {
  font-size: 30px;
  display: inline-block;
  height: auto;
  top: 20px;
  line-height: 0.3;
  position: relative;
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__author {
  margin-top: 20px;
  padding-left: 20px;
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__author h6 {
  color: #273272;
  font-size: 15px;
  margin-bottom: 3px;
}

.testimonial-slider__container-three .testimonial-slider--box .testimonial-slider__author .company-name {
  font-size: 13px;
  color: #6b6b6b;
  font-weight: 500;
}

.testimonial-slider__container-three .testimonial-box-bg {
  position: relative;
  min-width: 150px;
  margin-right: 50px;
}

.testimonial-slider__container-three .testimonial-box-bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 88%;
  width: 100%;
  background: #ddd;
}

@media only screen and (max-width: 575px) {
  .testimonial-slider__container-three .testimonial-box-bg::before {
    position: inherit;
  }
}

.testimonial-slider__container-three .testimonial-box-bg.testimonial-three-images {
  padding: 12px 12px 0;
}

.testimonial-slider__container-three .testimonial-box-bg.testimonial-three-images .img-box {
  z-index: 1;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .testimonial-slider__container-three .testimonial-box-bg.testimonial-three-images {
    text-align: center;
    margin-bottom: 30px;
  }
}

.testimonial-slider__container-three .swiper-nav-button {
  background-image: none;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  width: 13px;
  height: 18px;
  bottom: 0;
  display: inline-block;
  position: relative;
  line-height: 18px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 60px;
  margin-right: 5px;
  opacity: 0.6;
}

.testimonial-slider__container-three .swiper-nav-button:hover {
  opacity: 1;
}

.section-title-border {
  position: relative;
  margin-right: 30px;
}

.section-title-border::after {
  height: 95%;
  width: 1px;
  background: #273272;
  right: 0;
  top: 50%;
  content: '';
  position: absolute;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-border {
    margin-right: 0px;
  }
  .section-title-border::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-border {
    margin-right: 0px;
  }
  .section-title-border::after {
    display: none;
  }
}

.ht-social-networks .item {
  display: inline-block;
  color: #fff;
  margin-right: 35px;
  font-size: 18px;
}

.ht-social-networks .item:last-child {
  margin-right: 0;
}

.ht-social-networks .item:hover {
  color: #ffffff;
}

.ht-social-networks.default-color .item {
  display: inline-block;
  color: #172541;
  margin-right: 35px;
  font-size: 18px;
}

.ht-social-networks.default-color .item:last-child {
  margin-right: 0;
}

.ht-social-networks.default-color .item:hover {
  color: #ffffff;
}

.ht-social-networks .social-link::before {
  color: #ccc;
}

.ht-social-networks.style-two-icon .item {
  display: inline-block;
  color: #273272;
  margin-right: 15px;
  font-size: 18px;
}

.ht-social-networks.style-two-icon .item:last-child {
  margin-right: 0;
}

.ht-social-networks.style-two-icon .item:hover {
  color: #ffffff;
}

.ht-social-networks.style-two-icon .item .social-link {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
}

.ht-social-networks.style-two-icon .item .social-link:hover {
  background: #273272;
  color: #ffffff;
}

/*================================  
=          17. Team Css          =
==================================*/
.single-team-box.style-01 {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  transition: all 0.3s ease-in-out;
}

.single-team-box.style-01 .team-content {
  padding: 30px 15px;
  text-align: center;
  background: #fbfbfb;
  position: relative;
}

.single-team-box.style-01 .team-content::after {
  position: absolute;
  background: #273272;
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  content: '';
  transition: all 0.3s ease-in-out;
}

.single-team-box.style-01 .team-content h4 {
  color: #273272;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-team-box.style-01 .team-content h4 {
    font-size: 20px;
  }
}

.single-team-box.style-01 .team-content .text-box {
  z-index: 4;
  position: relative;
}

.single-team-box.style-01:hover {
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

.single-team-box.style-01:hover .team-content::after {
  height: 100%;
  z-index: 3;
}

.single-team-box.style-01:hover .team-content h4 {
  color: #ffffff;
}

.single-team-box.style-01:hover .team-content p {
  color: #ffffff;
}

.single-team-box.style-01:hover .social-creative-box {
  opacity: 1;
  visibility: visible;
}

.single-team-box.style-01:hover .social-creative-box .style-three {
  transform: translateY(-24px);
}

.single-team-box.style-01 .social-creative-box {
  opacity: 0;
  visibility: hidden;
  z-index: 4;
  position: absolute;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.single-team-box.style-01 .ht-social-networks.style-three {
  background: #F4F4F4;
  padding: 10px;
  margin: auto;
  text-align: center;
  max-width: 220px;
  margin-top: 0px;
  transition: all 0.5s ease-in-out;
  transform: translateY(30px);
}

.single-team-box.style-01 .ht-social-networks.style-three .item {
  display: inline-block;
  color: #172541;
  margin-right: 35px;
  font-size: 18px;
}

.single-team-box.style-01 .ht-social-networks.style-three .item:last-child {
  margin-right: 0;
}

.single-team-box.style-02 {
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.single-team-box.style-02 .team-image {
  position: relative;
}

.single-team-box.style-02 .team-image img {
  width: 100%;
}

.single-team-box.style-02 .team-content {
  padding: 15px 15px;
  text-align: center;
  background: #ebebeb;
  position: relative;
}

.single-team-box.style-02 .team-content::after {
  position: absolute;
  background: #273272;
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  content: '';
  transition: all 0.3s ease-in-out;
}

.single-team-box.style-02 .team-content h4 {
  color: #273272;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-team-box.style-02 .team-content h4 {
    font-size: 20px;
  }
}

.single-team-box.style-02 .team-content .text-box {
  z-index: 4;
  position: relative;
}

.single-team-box.style-02:hover .team-content::after {
  height: 100%;
  z-index: 3;
}

.single-team-box.style-02:hover .team-content h4 {
  color: #ffffff;
}

.single-team-box.style-02:hover .team-content p {
  color: #ffffff;
}

.single-team-box.style-02:hover .ht-social-networks.style-four .item {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.single-team-box.style-02 .ht-social-networks.style-four {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.single-team-box.style-02 .ht-social-networks.style-four .item {
  display: block;
  left: 17px;
  margin-right: 17px;
  margin-bottom: 5px;
  opacity: 0;
  visibility: hidden;
  position: relative;
  text-align: center;
  transition: all .3s ease 0s;
}

.single-team-box.style-02 .ht-social-networks.style-four .item a {
  font-size: 12px;
  border-radius: 50px;
  color: #ffffff;
  height: 25px;
  line-height: 25px;
  width: 25px;
  background-color: #273272;
}

.single-team-box.style-02 .ht-social-networks.style-four .item a:hover {
  background: #f3525a;
}

.single-team-box.style-02 .ht-social-networks.style-four .item:nth-child(1) {
  transition-delay: 100ms;
}

.single-team-box.style-02 .ht-social-networks.style-four .item:nth-child(2) {
  transition-delay: 150ms;
}

.single-team-box.style-02 .ht-social-networks.style-four .item:nth-child(3) {
  transition-delay: 200ms;
}

.single-team-box.style-02 .ht-social-networks.style-four .item:nth-child(4) {
  transition-delay: 200ms;
}

.single-team-box.style-03 {
  margin-bottom: 30px;
}

.single-team-box.style-03 .team-image {
  position: relative;
}

.single-team-box.style-03 .team-image a {
  display: block;
}

@media only screen and (max-width: 575px) {
  .single-team-box.style-03 .team-image img {
    width: 100%;
  }
}

.single-team-box.style-03 .ht-social-networks.style-four {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.single-team-box.style-03 .ht-social-networks.style-four .item {
  display: block;
  left: 17px;
  margin-right: 17px;
  margin-bottom: 5px;
  opacity: 0;
  visibility: hidden;
  position: relative;
  text-align: center;
  transition: all .3s ease 0s;
}

.single-team-box.style-03 .ht-social-networks.style-four .item a {
  font-size: 12px;
  border-radius: 50px;
  color: #ffffff;
  height: 25px;
  line-height: 25px;
  width: 25px;
  background-color: #273272;
}

.single-team-box.style-03 .ht-social-networks.style-four .item a:hover {
  background: #f3525a;
}

.single-team-box.style-03 .ht-social-networks.style-four .item:nth-child(1) {
  transition-delay: 100ms;
}

.single-team-box.style-03 .ht-social-networks.style-four .item:nth-child(2) {
  transition-delay: 150ms;
}

.single-team-box.style-03 .ht-social-networks.style-four .item:nth-child(3) {
  transition-delay: 200ms;
}

.single-team-box.style-03 .ht-social-networks.style-four .item:nth-child(4) {
  transition-delay: 200ms;
}

.single-team-box.style-03 .team-content {
  margin-top: 20px;
}

.single-team-box.style-03 h4 {
  color: #273272;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-team-box.style-03 h4 {
    font-size: 20px;
  }
}

.single-team-box.style-03 p {
  font-weight: 300;
}

.single-team-box.style-03:hover .ht-social-networks.style-four .item {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.team-style-3-title {
  min-height: 400px;
  top: 0;
  height: 100%;
  padding: 26px;
  position: relative;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .team-style-3-title {
    margin-right: 0px;
    min-height: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .team-style-3-title {
    margin-right: 0px;
    min-height: 260px;
  }
}

.team-style-3-title::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(39, 50, 114, 0.95);
}

.team-style-3-title .team-style-3-title-bg {
  transform: rotate(-90deg) translateY(-50%);
  position: absolute;
  bottom: 140px;
  min-width: 340px;
  max-width: 300px;
  left: 0%;
  /* @media only screen and (max-width: 767px){
            min-height: 200px;
            bottom: 140px;
            min-width: 340px;
            max-width: 300px;
            left: 0%;
            
        } */
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .team-style-3-title .team-style-3-title-bg {
    transform: rotate(0deg) translateY(0%);
    padding: 20px;
    bottom: auto;
    min-width: 95%;
  }
}

.team-style-3-title .team-style-3-title-bg h3 {
  color: #ffffff;
}

.team-style-3-title .team-style-3-title-bg p {
  color: #ffffff;
}

/*===================================
=         18. Projcet Css           =
=====================================*/
.messonry-button button {
  padding: 0;
  border: 0 none;
  position: relative;
  background: transparent;
}

.messonry-button button:last-child .filter-text {
  margin-right: 0;
  padding-right: 0;
}

.messonry-button button span {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: 5px 10px;
  position: relative;
  border-bottom: 2px solid transparent;
}

.messonry-button button span:last-child {
  margin-right: 0;
  padding-right: 0;
}

.messonry-button button span.filter-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.74;
  color: #273272;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.messonry-button button span.filter-counter {
  position: absolute;
  top: 0;
  left: 50%;
  visibility: hidden;
  margin: 0 auto;
  min-width: 34px;
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  font-size: 12px;
  background-color: #273272;
  color: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 600;
}

.messonry-button button span.filter-counter::before {
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  display: block;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 4px solid #273272;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top-color: #273272;
}

.messonry-button button:hover span.filter-counter {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.messonry-button button:hover span.filter-text {
  color: #273272;
}

.messonry-button button.is-checked span.filter-text {
  color: #f3525a;
}

.single-portfolio-item {
  position: relative;
  display: block;
}

.single-portfolio-item .single-portfolio__thumbnail img {
  width: 100%;
}

.single-portfolio-item:hover .project-hover-01 {
  background-color: rgba(243, 82, 90, 0.8);
}

.single-portfolio-item:hover .project-hover-01 .post-overlay-title {
  opacity: 1;
  transform: translateY(0);
}

.single-portfolio-item:hover .project-hover-01 .inner-icon {
  opacity: 1;
  transform: translateX(0);
}

.single-portfolio-item .project-hover-01 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100.1%;
  height: 100%;
  padding: 30px;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
}

.single-portfolio-item .project-hover-01 .post-overlay-title {
  opacity: 0;
  position: absolute;
  bottom: 2.71rem;
  color: #000;
  transform: translateY(10px);
  transition: .3s ease;
}

.single-portfolio-item .project-hover-01 .inner-icon {
  opacity: 0;
  position: absolute;
  color: #fff;
  right: 40px;
  top: 30px;
  font-size: 22px;
  transition: .3s ease;
  transform: translateX(20px);
}

.ht-gradation.style-01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-gradation.style-01 {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .ht-gradation.style-01 {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .ht-gradation.style-01 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ht-gradation.style-01 .circle-wrap {
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}

.ht-gradation.style-01 .circle {
  position: relative;
  z-index: 1;
  background: #f7f7f7;
  width: 200px;
  height: 200px;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-gradation.style-01 .circle:hover {
  transform: translateY(-5px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-gradation.style-01 .circle {
    width: 160px;
    height: 160px;
  }
  .ht-gradation.style-01 .circle .heading {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-gradation.style-01 .circle {
    width: 140px;
    height: 140px;
  }
  .ht-gradation.style-01 .circle .heading {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-gradation.style-01 .circle {
    width: 160px;
    height: 160px;
  }
  .ht-gradation.style-01 .circle .heading {
    font-size: 16px;
  }
}

.ht-gradation.style-01 .heading {
  font-weight: 600;
  color: #273272;
}

.ht-gradation.style-01 .box-image {
  position: relative;
  margin-bottom: 20px;
}

.ht-gradation.style-01 .box-image .hover-images {
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.5 0.9 ease;
}

@media only screen and (max-width: 767px) {
  .ht-gradation.style-01 .item:nth-child(4) {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .ht-gradation.style-01 .item:nth-child(even) {
    display: none;
  }
}

.ht-gradation.style-01 .item:hover .circle {
  background: #273272;
  box-shadow: 0px 0px 62.9136px 5.0864px rgba(39, 50, 114, 0.33);
}

.ht-gradation.style-01 .item:hover .heading {
  color: #ffffff;
}

.ht-gradation.style-01 .item:hover .hover-images {
  opacity: 1;
  visibility: visible;
}

.ht-gradation.style-01 .item:hover .default-image {
  opacity: 0;
  visibility: hidden;
}

/*=======================================
=           19. Timeline Css           =
=========================================*/
.ht-timeline.style-01.mb-different .item {
  position: relative;
  padding: 0px 0 17px 65px;
}

.ht-timeline.style-01.mb-different .item:last-child {
  padding-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01.mb-different .item {
    padding: 0px 0 17px 35px;
  }
}

.ht-timeline.style-01 .item {
  position: relative;
  padding: 0px 0 35px 65px;
}

.ht-timeline.style-01 .item:last-child {
  padding-bottom: 0;
}

.ht-timeline.style-01 .item:last-child::before {
  display: none;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .item {
    padding: 0px 0 35px 35px;
  }
}

.ht-timeline.style-01 .item::before {
  position: absolute;
  left: 30px;
  margin-left: -1px;
  height: 100%;
  width: 1px;
  content: '';
  background: #273272;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .item::before {
    left: 5px;
    margin-top: 2px;
  }
}

.ht-timeline.style-01 .dots .middle-dot {
  position: absolute;
  top: 0px;
  left: 30px;
  transform: translate(-50%, 0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 2;
  background: #273272;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-01 .dots .middle-dot {
    left: 5px;
  }
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: -5px;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body h5 {
  font-size: 18px;
  margin-bottom: 3px;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body p {
  line-height: 1.3;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col .content-body p span {
  font-weight: 300;
}

.ht-timeline.style-01 .tm-timeline-list .timeline-col:last-child {
  padding-bottom: 0;
}

.ht-timeline.style-02.mb-different .item {
  position: relative;
  padding: 0px 0 17px 65px;
}

.ht-timeline.style-02.mb-different .item:last-child {
  padding-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-02.mb-different .item {
    padding: 0px 0 17px 35px;
  }
}

.ht-timeline.style-02 .item {
  position: relative;
  padding: 0px 0 25px 65px;
}

.ht-timeline.style-02 .item:last-child {
  padding-bottom: 0;
}

.ht-timeline.style-02 .item:last-child::before {
  display: none;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-02 .item {
    padding: 0px 0 25px 35px;
  }
}

.ht-timeline.style-02 .item::before {
  position: absolute;
  left: 30px;
  margin-left: -1px;
  height: 100%;
  width: 1px;
  content: '';
  background: #273272;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-02 .item::before {
    left: 5px;
    margin-top: 2px;
  }
}

.ht-timeline.style-02 .dots .middle-dot {
  position: absolute;
  top: 0px;
  left: 30px;
  transform: translate(-50%, 0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 2;
  background: #273272;
}

@media only screen and (max-width: 575px) {
  .ht-timeline.style-02 .dots .middle-dot {
    left: 5px;
  }
}

.ht-timeline.style-02 .tm-timeline-list .timeline-col {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: -5px;
}

.ht-timeline.style-02 .tm-timeline-list .timeline-col .content-body h5 {
  font-size: 18px;
  margin-bottom: 3px;
}

.ht-timeline.style-02 .tm-timeline-list .timeline-col .content-body p {
  line-height: 1.3;
}

.ht-timeline.style-02 .tm-timeline-list .timeline-col .content-body p span {
  font-weight: 300;
}

.ht-timeline.style-02 .tm-timeline-list .timeline-col:last-child {
  padding-bottom: 0;
}

/*=============================================
=            20. Contact Us Css            =
=============================================*/
.contact-form__one {
  padding: 50px;
  background: #172541;
}

.contact-form__one .contact-inner {
  margin-bottom: 30px;
}

.contact-form__one .contact-inner input,
.contact-form__one .contact-inner textarea {
  width: 100%;
  background: transparent;
  color: #ffffff;
  border: 1px solid #585a5e;
  padding: 6px 10px;
  font-size: 15px;
  font-weight: 300;
}

.contact-form__one .contact-inner textarea {
  width: 100%;
  height: 190px;
}

@media only screen and (max-width: 767px) {
  .contact-form__one {
    padding: 20px;
  }
}

.contact-form__one .personal-contact-btn {
  font-size: 18px;
  border: none;
}

.contact-form__two {
  padding: 0 50px;
}

.contact-form__two .contact-inner {
  margin-bottom: 30px;
}

.contact-form__two .contact-inner input,
.contact-form__two .contact-inner textarea {
  width: 100%;
  background: transparent;
  color: #273272;
  border: 1px solid #e5e5e5;
  padding: 6px 10px;
  font-size: 15px;
  font-weight: 300;
}

.contact-form__two .contact-inner textarea {
  width: 100%;
  height: 190px;
}

@media only screen and (max-width: 767px) {
  .contact-form__two {
    padding: 0px;
  }
}

.contact-form__two .personal-contact-btn {
  font-size: 18px;
  border: none;
}

.contact-form__three {
  padding-right: 20px;
}

.contact-form__three .contact-inner {
  margin-bottom: 30px;
}

.contact-form__three .contact-inner input,
.contact-form__three .contact-inner textarea {
  width: 100%;
  background: transparent;
  color: #273272;
  border: 1px solid #e5e5e5;
  padding: 6px 10px;
  font-size: 15px;
  font-weight: 300;
}

.contact-form__three .contact-inner textarea {
  width: 100%;
  height: 190px;
}

@media only screen and (max-width: 767px) {
  .contact-form__three {
    padding: 0px;
  }
}

.contact-form__three .personal-contact-btn {
  font-size: 18px;
  border: none;
}

.single-contact-info-wrap .icon-box {
  height: 70px;
  min-width: 70px;
  background: #273272;
  margin-right: 25px;
  line-height: 70px;
  text-align: center;
  border-radius: 5000px;
  box-shadow: 0px 0px 29px 0px rgba(39, 50, 114, 0.31);
}

/*=============================================
=            21. Pricing  Css            =
=============================================*/
.pricing-table-one .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 34px 20px 40px;
  background: #f6f7ff;
  margin-bottom: 30px;
}

.pricing-table-one .pricing-table__inner .pricing__tab-menu .tab__item a {
  background: #273272;
  color: #ffffff;
  width: 100px;
}

.pricing-table-one .pricing-table__inner .pricing__tab-menu .tab__item a.active {
  background: #f3525a;
}

.pricing-table-one .pricing-table__title {
  color: #273272;
  margin-bottom: 10px;
}

.pricing-table-one .pricing-table__price-wrap {
  text-align: center;
  margin: 20px 0 0 0;
}

.pricing-table-one .pricing-table__price-wrap .price {
  font-size: 24px;
  line-height: .9;
  font-weight: 600;
  color: #273272;
}

.pricing-table-one .pricing-table__price-wrap .price__two {
  background: #273272;
  display: inline-block;
  padding: 12px 20px;
  color: #ffffff;
}

.pricing-table-one .pricing-table__body {
  margin-top: 30px;
}

.pricing-table-one .pricing-table__list li {
  position: relative;
  display: block;
  padding: 5px 0;
}

.pricing-table-one .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-one .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-one .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-one .pricing-table__footer .ht-btn--outline {
  border-width: 1px;
}

.pricing-table-one .pricing-table__feature-mark {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-top: 88px solid #ee7455;
  border-bottom: 88px solid transparent;
  border-left: 88px solid transparent;
}

.pricing-table-one .pricing-table__feature-mark span {
  position: absolute;
  top: -72px;
  right: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
}

.pricing-table-one .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
  transform: translateY(-5px);
}

.pricing-table-one .pricing-table--popular .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__inner {
  border: 0px solid transparent;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__title {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .currency {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .price {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .period {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__list li {
  color: #ffffff;
}

.pricing-table-two .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 90px 20px 100px;
  background: #f6f7ff;
  margin-bottom: 30px;
}

.pricing-table-two .pricing-table__inner::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../images/patterns/pricing-01.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-repeat: no-repeat;
  background-position: top;
}

.pricing-table-two .pricing-table__inner::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../images/patterns/pricing-02.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-repeat: no-repeat;
  background-position: bottom;
}

.pricing-table-two .pricing-table__inner .price {
  font-size: 24px;
  line-height: .9;
  font-weight: 600;
  margin-bottom: 20px;
}

.pricing-table-two .pricing-table__inner .price__three {
  color: #273272;
}

.pricing-table-two .pricing-table__title {
  color: #273272;
  line-height: 30px;
}

.pricing-table-two .pricing-table__price-wrap {
  text-align: center;
  margin: 0;
}

.pricing-table-two .pricing-table__body {
  margin-top: 30px;
}

.pricing-table-two .pricing-table__list li {
  position: relative;
  display: block;
  padding: 5px 0;
}

.pricing-table-two .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-two .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-two .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-two .pricing-table__footer .ht-btn--outline {
  border-width: 1px;
}

/*=============================================
=            22. Corporate Pages Css          =
=============================================*/
.corporate-bg-01 {
  background-image: url(../images/hero/home-corporate-bg-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.corporate-bg-02 {
  background-image: url(../images/hero/home-corporate-bg-02.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.corporate-hero {
  height: 890px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ddd;
}

.corporate-hero .corporate-hero-text {
  margin-top: 50px;
}

.corporate-hero .hero-sub-title {
  color: #172541;
  margin-bottom: 15px;
}

.corporate-hero h1 {
  font-size: 63px;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .corporate-hero {
    height: 700px;
  }
  .corporate-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .corporate-hero {
    height: 600px;
  }
  .corporate-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .corporate-hero {
    height: 490px;
  }
  .corporate-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .corporate-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .corporate-hero {
    height: 400px;
  }
  .corporate-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .corporate-hero h1 {
    font-size: 30px;
  }
}

.hero-area-wrap {
  position: relative;
}

.call-to-number {
  right: 90px;
  left: auto;
  position: absolute;
  bottom: -40px;
}

.call-to-number a {
  background: #273272;
  padding: 25px 35px;
  border-radius: 5000px;
  color: #ffffff;
  font-size: 24px;
}

.call-to-number a img {
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1499px) {
  .call-to-number a {
    padding: 20px 35px;
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .call-to-number {
    display: none;
  }
}

.feature-images__one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .feature-images__one {
    flex-wrap: wrap;
  }
}

.custom_4.active {
  padding: 40px 52px;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .custom_4.active {
    padding: 40px 30px;
  }
}

.ht-box-images.style-01 .image-box-wrap {
  max-width: 295px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-box-images.style-01 .image-box-wrap {
    max-width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-box-images.style-01 .image-box-wrap {
    max-width: 450px;
  }
}

.ht-box-images.style-01 .image-box-wrap .content .text {
  font-weight: 300;
  color: #172541;
}

.brand-box-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.brand-box-wrap .single-brand-box {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .brand-box-wrap {
    justify-content: flex-start;
  }
  .brand-box-wrap .single-brand-box {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .brand-box-wrap {
    justify-content: center;
    text-align: center;
  }
  .brand-box-wrap .single-brand-box {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-us-images img {
    width: 100%;
  }
}

/* FAQ */
.faq-two-wrapper .panel.panel-default {
  margin-bottom: 20px;
}

.faq-two-wrapper .card, .faq-two-wrapper .card-header {
  border-radius: 0;
}

.faq-two-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
}

.faq-two-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-two-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}

.faq-two-wrapper .card-header h5 button {
  font-size: 18px;
  border: none;
  width: 100%;
  text-align: left;
  color: #273272;
  padding: 20px 48px;
  position: relative;
  white-space: normal;
  line-height: 1.2;
  font-weight: 700;
  border-radius: 5px;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .faq-two-wrapper .card-header h5 button {
    padding-left: 48px;
    padding-right: 10px;
  }
}

.faq-two-wrapper .card-header h5 button .accordion-number {
  left: 0px;
  top: 45%;
  transform: translateY(-50%);
  position: absolute;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 15px;
  text-align: center;
  background: #273272;
  color: #ffffff;
  display: inline-block;
  z-index: 4;
}

.faq-two-wrapper .card-header h5 button .acco-number-box {
  position: absolute;
  height: 25px;
  width: 25px;
  display: inline-block;
  background: #f3525a;
  left: 5px;
  bottom: 16px;
}

.faq-two-wrapper .card-header h5 button:hover, .faq-two-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #f3525a;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] {
  color: #f3525a;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] span i {
  color: #f3525a;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-two-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-two-wrapper .card-body {
  padding: 0px 48px 20px;
}

.service-details-left-side {
  padding-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .service-details-left-side {
    padding-right: 0px;
  }
}

.ht-tab-menu-01 li {
  width: 33.333%;
  text-align: center;
  border-left: 3px solid #fff;
}

.ht-tab-menu-01 li:first-child {
  border-left: none;
}

@media only screen and (max-width: 767px) {
  .ht-tab-menu-01 li {
    width: 100%;
    border-left: 0px solid #fff;
    border-bottom: 3px solid #fff;
  }
  .ht-tab-menu-01 li:last-child {
    border-bottom: none;
  }
}

.ht-tab-menu-01 li a {
  background: #f3525a;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  padding: 16px;
}

.ht-tab-menu-01 li .active {
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
  background: #fff;
  color: #273272;
}

.ht-tab__content-01 {
  background: #273272;
  padding: 60px 50px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .ht-tab__content-01 {
    padding: 30px 20px;
  }
}

/*=============================================
=            23. Personal Pages Css          =
=============================================*/
.personal-hero {
  position: relative;
  /*
    height: 944px;*/
  overflow: hidden;
  display: flex;
  align-items: center;
}

.personal-hero .corporate-hero-text {
  margin-top: 50px;
}

.personal-hero .hero-sub-title {
  color: #172541;
  margin-bottom: 15px;
}

.personal-hero h1 {
  font-size: 63px;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .personal-hero {
    /*height: 700px;*/
  }
  .personal-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-hero {
    /*height: 600px;*/
  }
  .personal-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-hero {
    /*height: 490px;*/
  }
  .personal-hero .corporate-hero-text {
    margin-top: 50px;
  }
  .personal-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-hero {
    /*height: 400px;*/
  }
  .personal-hero .corporate-hero-text {
    margin-top: 50px;
  }
  .personal-hero h1 {
    font-size: 30px;
  }
}

.personal-inner-hero-images {
  margin-top: 180px;
  margin-left: -45px;
  margin-right: -300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .personal-inner-hero-images {
    margin-top: 100px;
    margin-left: -100px;
    margin-right: -140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-inner-hero-images {
    margin-top: 80px;
    margin-left: -100px;
    margin-right: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-inner-hero-images {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;
    margin-left: -145px;
    margin-right: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-inner-hero-images {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.personal-inner-hero-images-two {
  margin-top: 90px;
  margin-left: -45px;
  margin-right: -300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .personal-inner-hero-images-two {
    margin-top: 100px;
    margin-left: -100px;
    margin-right: -140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-inner-hero-images-two {
    margin-top: 80px;
    margin-left: -100px;
    margin-right: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-inner-hero-images-two {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;
    margin-left: -145px;
    margin-right: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-inner-hero-images-two {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.social-personal-box {
  position: absolute;
  right: 50px;
  bottom: 40px;
}

.personal-about-left-wrap {
  background: #f1f1f1;
  padding: 90px 82px 90px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-about-left-wrap {
    padding: 60px 52px 60px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-about-left-wrap {
    padding: 60px 52px 60px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-about-left-wrap {
    padding: 60px 15px 60px 15px;
  }
}

.personal-about-right-wrap {
  background: #f6f5f5;
  padding: 90px 82px 90px 70px;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-about-right-wrap {
    padding: 60px 52px 60px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-about-right-wrap {
    padding: 60px 52px 60px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-about-right-wrap {
    padding: 60px 15px 60px 15px;
  }
}

.about-col-05__right {
  width: 515px;
  max-width: 100%;
  margin-left: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-col-05__right {
    margin-left: 0px;
  }
}

.personal-box {
  position: relative;
}

.personal-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 85%;
  width: 100%;
  background: #ddd;
}

.personal-about-left-images {
  padding: 50px;
}

@media only screen and (max-width: 767px) {
  .personal-about-left-images {
    padding: 30px;
  }
}

.personal-about-left-images .img-box {
  z-index: 1;
  position: relative;
}

.personal-about-left-images .personal-about-btn-box-two {
  position: absolute;
  bottom: 80px;
  z-index: 1;
  left: 25px;
}

.pl-lg-100 {
  padding-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .pl-lg-100 {
    padding-left: 0;
  }
}

.personal-skill-content-box-one {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .personal-skill-content-box-one {
    padding-left: 0;
  }
}

.personal-skill-content-box-one .progress-bar--one {
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .personal-skill-content-box-one .progress-bar--one {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-skill-content-box-one .progress-bar--one {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-skill-content-box-two {
    padding-left: 0px;
  }
}

.personal-skill-content-box-two .progress-bar--one {
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .personal-skill-content-box-two .progress-bar--one {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-skill-content-box-two .progress-bar--one {
    padding-right: 0px;
  }
}

.progress-bar--one .progress-charts {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.progress-bar--two {
  overflow: hidden;
}

.progress-bar--two .progress-charts {
  padding-bottom: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.personal-box-skill {
  position: relative;
  max-width: 400px;
}

.personal-box-skill::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 88%;
  width: 100%;
  background: #ddd;
}

.personal-box-skill.personal-skill-right-images {
  padding: 30px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .personal-box-skill.personal-skill-right-images {
    padding: 30px;
  }
}

.personal-box-skill.personal-skill-right-images .img-box {
  z-index: 1;
  position: relative;
}

.personal-box-skill.personal-skill-right-images .personal-about-btn-box-two {
  position: absolute;
  bottom: 80px;
  z-index: 1;
  left: 25px;
}

.progress-charts {
  overflow: hidden;
}

.progress-charts .heading {
  color: #273272;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
}

.progress-charts .progress {
  height: 14px;
  overflow: visible;
  font-size: 14px;
  background-color: #f6f5f5;
  border-radius: 0;
}

.progress-charts .progress--two {
  width: 230px;
  border-radius: 50px;
  height: 24px;
}

.progress-charts .progress--two .progress-bar {
  border-radius: 50px;
}

.progress-charts .progress .progress-bar {
  position: relative;
  background: #273272;
  color: #273272;
}

.progress-charts .progress .progress-bar::after, .progress-charts .progress .progress-bar::before {
  content: '';
  position: absolute;
  right: -4px;
  top: 50%;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.progress-charts .progress .progress-bar::after {
  height: 13px;
  width: 13px;
  border: 3px solid currentColor;
}

.progress-charts .progress .progress-bar::before {
  height: 28px;
  width: 28px;
  border: 1px solid currentColor;
  opacity: .3;
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  right: -45px;
  font-size: 14px;
  font-weight: 500;
  top: -40px;
  color: #273272;
  line-height: 2;
}

.progress-charts .progress__two .progress-bar span.percent-label {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-top: -2px;
}

.personal-info-box {
  max-width: 840px;
  text-align: center;
  margin: auto;
  margin-top: auto;
  padding: 16px 15px;
  margin-top: -60px;
}

.personal-service-area {
  overflow: hidden;
}

.flexible-image-slider-wrap {
  margin-right: -500px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flexible-image-slider-wrap {
    margin-right: -300px !important;
  }
}

@media only screen and (max-width: 767px) {
  .flexible-image-slider-wrap {
    margin-right: -100px !important;
  }
}

@media only screen and (max-width: 575px) {
  .flexible-image-slider-wrap {
    margin-right: -0px !important;
  }
}

.personal-service-box-wrap {
  position: relative;
  max-width: 425px;
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .personal-service-box-wrap {
    margin-top: 0px;
    margin-bottom: 50px;
  }
}

.personal-service-box-wrap::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 88%;
  width: 100%;
  background: #ddd;
}

.personal-service-box-wrap.personal-service-left-images {
  padding: 30px 30px 0;
}

.personal-service-box-wrap.personal-service-left-images .img-box {
  z-index: 1;
  position: relative;
}

.personal-service-box-wrap.personal-service-left-images .personal-about-btn-box-two {
  position: absolute;
  bottom: 80px;
  z-index: 1;
  left: 25px;
}

.timeline-title {
  margin-bottom: 40px;
}

.timeline-title h4 {
  position: relative;
  padding-left: 30px;
}

.timeline-title h4::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  background: #f3525a;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.blog-col-05__right {
  width: 515px;
  max-width: 100%;
  margin-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .blog-col-05__right {
    margin-left: 0px;
  }
}

.personal-blog-content-wrap .post-meta {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .personal-blog-content-wrap .post-meta {
    flex-direction: column;
  }
}

.personal-blog-content-wrap .post-meta .author-name h4, .personal-blog-content-wrap .post-meta .post-data h4 {
  color: #273272;
}

.personal-blog-content-wrap .post-meta .author-name span, .personal-blog-content-wrap .post-meta .post-data span {
  font-size: 18px;
  color: #172541;
  font-weight: 500;
}

.personal-post-meta-tag {
  display: flex;
}

.personal-post-meta-tag .tag-list h4 {
  color: #273272;
  padding-right: 15px;
}

.personal-post-meta-tag .tag-list span {
  font-size: 18px;
  color: #172541;
  font-weight: 500;
}

.personal-post-meta-tag .post-share {
  margin-left: 60px;
}

@media only screen and (max-width: 767px) {
  .personal-post-meta-tag {
    flex-direction: column;
  }
  .personal-post-meta-tag .post-share {
    margin-left: 00px;
  }
}

.personal-blog-content-details {
  max-width: 536px;
}

.personal-blog-content-details p {
  font-weight: 300;
}

.personal-blog-content-details p::first-letter {
  font-size: 200%;
  line-height: 1;
  font-weight: bold;
}

.personal-portfolio-slider__container .swiper-nav-button, .personal-portfolio-two-slider__container .swiper-nav-button {
  kground-image: none;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  width: 13px;
  height: 18px;
  bottom: 0;
  display: inline-block;
  position: relative;
  line-height: 18px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 60px;
  margin-right: 5px;
  opacity: 0.6;
}

.personal-portfolio-slider__container .swiper-nav-button:hover, .personal-portfolio-two-slider__container .swiper-nav-button:hover {
  opacity: 1;
}

.personal-portfolio-slider__container .swiper-nav-button.swiper-button-prev, .personal-portfolio-two-slider__container .swiper-nav-button.swiper-button-prev {
  right: 0;
}

.personal-share-title {
  text-decoration: underline;
}

.personal-blog-details-wrap .blog-lg-images img {
  width: 100%;
}

.personal-blog-details-wrap .ht-social-networks.default-color .item {
  margin-right: 25px;
  font-size: 15px;
}

.team-widget-details {
  max-width: 330px;
  margin-left: auto;
}

.team-widget-details .widget-banner-box {
  padding: 65px 15px 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .team-widget-details {
    margin: 40px auto auto;
  }
}

.team-widget-details h3,
.team-widget-details h4 {
  color: #f4be54;
}

.team-personal-info-list > li {
  margin-bottom: 25px;
}

.team-personal-info-list > li > span {
  font-weight: 600;
  text-decoration: underline;
  font-size: 24px;
  color: #172541;
  margin-right: 10px;
}

.team-personal-info-list > li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .team-personal-info-list > li {
    margin-bottom: 15px;
  }
  .team-personal-info-list > li > span {
    font-size: 18px;
  }
}

.ht-social-team li {
  display: inline-block;
  margin-left: 20px;
}

.personal-testimonials-tab-list {
  float: right;
  margin-top: -220px;
  position: relative;
  z-index: 3;
  margin-left: -18px;
  margin-right: -18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-testimonials-tab-list {
    margin-top: -170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-testimonials-tab-list {
    margin-bottom: 0px;
    float: inherit;
    margin-top: 30px;
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .personal-testimonials-tab-list {
    margin-bottom: 0px;
    float: inherit;
    margin-top: 30px;
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    justify-content: center;
  }
}

.personal-testimonials-tab-list li {
  padding: 0 9px;
}

.personal-testimonials-tab-list li a {
  display: block;
}

.personal-testimonials-tab-list li a.active {
  box-shadow: 0px 10px -8px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-testimonials-tab-list li {
    width: 148px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-testimonials-tab-list li {
    width: 148px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-testimonials-tab-list li {
    width: 128px;
    padding-top: 18px;
  }
}

.personal-testimonials-details p {
  margin-top: 160px;
  position: relative;
  padding-left: 30px;
  max-width: 535px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-testimonials-details p {
    margin-top: 148px;
  }
}

@media only screen and (max-width: 767px) {
  .personal-testimonials-details p {
    margin-top: 30px;
  }
}

.personal-testimonials-details p::after {
  position: absolute;
  content: "”";
  right: 0;
  bottom: -32px;
  font-size: 32px;
}

.personal-testimonials-details p::before {
  position: absolute;
  content: "“";
  left: 4px;
  top: -12px;
  font-size: 32px;
}

.personal-testimonials-image {
  position: relative;
}

.personal-testimonials-image span {
  background: #f1f1f1;
  color: #333;
  padding: 10px 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  transform: rotate(-90deg);
  display: inline-block;
  right: -60px;
  top: 45%;
  position: absolute;
  border-radius: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-testimonials-image span {
    right: -80px;
  }
}

/*=============================================
=            24. Creative Pages Css          =
=============================================*/
.creative-hero {
  height: 890px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.creative-hero .creative-hero-text {
  margin-top: 50px;
}

.creative-hero .hero-sub-title {
  color: #172541;
  margin-bottom: 15px;
}

.creative-hero h1 {
  font-size: 63px;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .creative-hero {
    height: 700px;
  }
  .creative-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .creative-hero {
    height: 600px;
  }
  .creative-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .creative-hero {
    height: 490px;
  }
  .creative-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .creative-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .creative-hero {
    height: 400px;
  }
  .creative-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .creative-hero h1 {
    font-size: 30px;
  }
}

.creative-about-us-images .creative-agency-about-img {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .creative-about-us-images .creative-agency-about-img {
    padding-left: 0px;
  }
}

.creative-about-us-images .creative-agency-about-img-pr {
  padding-right: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .creative-about-us-images .creative-agency-about-img-pr {
    padding-left: 0px;
  }
}

.creative-agency-about-us-content.pr-50 {
  padding-right: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .creative-agency-about-us-content.pr-50 {
    padding-right: 0px;
  }
}

.creative-agency-about-us-content.pl-50 {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .creative-agency-about-us-content.pl-50 {
    padding-left: 0px;
  }
}

.about-inner-contact-info {
  max-width: 410px;
  background: #273272;
  padding: 50px;
  margin-top: -100px;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .about-inner-contact-info {
    max-width: 310px;
    padding: 40px 20px;
  }
}

.about-inner-btn {
  background: #273272;
  padding: 17px 50px;
  margin-top: -38px;
  position: relative;
  float: right;
}

.services-hight {
  height: 483px;
}

.single-working-process {
  margin-bottom: 30px;
}

.single-working-process h3 {
  font-weight: 400;
  line-height: 0.9;
  border-bottom: 2px solid #273272;
  display: inline-block;
  margin-bottom: 23px;
}

.single-working-process span {
  font-weight: 400;
  font-size: 24px;
  display: block;
  line-height: 1.3;
}

/**
 * Roundbox
 */
.roundbox {
  position: relative;
  height: 360px;
  width: 360px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.roundbox:before {
  content: "";
  background: url(../images/icons/creative_agency-counter-sercale.png) no-repeat scroll center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform-origin: center center;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .roundbox:before {
    display: none;
  }
}

.roundbox::after {
  left: 0;
  top: 0;
  height: 150px;
  width: 150px;
  background: url(../images/icons/counter-center.png) no-repeat scroll center;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .roundbox::after {
    display: none;
  }
}

.roundbox-block {
  text-align: center;
  display: inline-block;
  position: absolute;
}

.roundbox-block:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roundbox-block:nth-child(2) {
  left: 0;
  top: 50%;
  transform: translate(-50%, -40%);
}

.roundbox-block:nth-child(3) {
  left: auto;
  right: 0;
  top: 50%;
  transform: translate(55%, -40%);
}

.roundbox-block:nth-child(4) {
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.roundbox-block h5 {
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
}

.roundbox-box {
  height: 140px;
  width: 140px;
  text-align: center;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
  border-radius: 100px;
  display: inline-block;
  background: #ffffff;
}

@media only screen and (max-width: 575px) {
  .roundbox-box {
    height: 125px;
    width: 125px;
  }
}

@media only screen and (max-width: 1400px) {
  .roundbox {
    height: 225px;
    width: 225px;
  }
  .roundbox-block h5 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .roundbox {
    height: 225px;
    width: 225px;
  }
  .roundbox-block h5 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .roundbox {
    height: auto;
    width: auto;
  }
  .roundbox-block {
    position: relative;
    transform: translate(0) !important;
    left: inherit !important;
    top: inherit !important;
    margin-top: 30px;
  }
  .roundbox-block:not(:last-child) {
    margin-right: 30px;
  }
  .roundbox-block h5 {
    margin-top: 15px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .roundbox {
    height: auto;
    width: auto;
  }
  .roundbox-block {
    position: relative;
    transform: translate(0) !important;
    left: inherit !important;
    top: inherit !important;
    margin-top: 20px;
    min-width: 120px;
  }
  .roundbox-block:not(:last-child) {
    margin-right: 30px;
  }
  .roundbox-block h5 {
    margin-top: 15px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .roundbox-block:not(:last-child) {
    margin-right: 15px;
  }
  .roundbox-icon {
    height: 65px;
    width: 65px;
    line-height: 65px;
    font-size: 26px;
  }
}

.testimonials-content-right-box {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .testimonials-content-right-box {
    padding-left: 0px;
  }
}

/*=============================================
=        25. Architecture Pages Css          =
=============================================*/
.architecture-hero {
  height: 888px;
  display: flex;
  align-items: center;
  background-color: #ddd;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .architecture-hero {
    height: 700px;
  }
  .architecture-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .architecture-hero {
    height: 600px;
  }
  .architecture-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .architecture-hero {
    height: 490px;
  }
  .architecture-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .architecture-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .architecture-hero {
    height: 480px;
  }
  .architecture-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .architecture-hero h1 {
    font-size: 30px;
  }
}

.swiper-slide * {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-1 h4 {
  animation-delay: 0.4s;
  animation-name: fadeInLeft;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-1 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInLeft;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-1 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInLeft;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-2 h4 {
  animation-delay: 0.4s;
  animation-name: rollIn;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-2 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInLeft;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-2 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInLeft;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-3 h4 {
  animation-delay: 0.4s;
  animation-name: fadeInUp;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-3 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInUp;
}

.architecture-hero-wrap .swiper-slide-active .layer-animation-3 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInUp;
}

.architecture-hero-wrap {
  position: relative;
}

.architecture-hero-wrap .hero-swiper-pagination-number {
  position: absolute;
  bottom: 50px;
  z-index: 1;
  right: 50px;
}

.architecture-hero-wrap .hero-swiper-pagination-number.swiper-pagination__right {
  padding-left: 70px;
}

.architecture-hero-wrap .hero-swiper-pagination-number.swiper-pagination__right::after {
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 50px;
  height: 1px;
}

@media only screen and (max-width: 767px) {
  .architecture-hero-wrap .hero-swiper-pagination-number {
    bottom: 20px;
    right: 20px;
  }
}

.architecture-hero-wrap .swiper-pagination-bullet {
  text-align: center;
  line-height: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  opacity: 1;
  background: transparent;
  margin-right: 30px;
}

.architecture-hero-wrap .swiper-pagination-bullet-active {
  color: #686868;
  background: transparent;
}

.architecture-hero-text-box {
  max-width: 770px;
  margin: auto;
  background: rgba(218, 221, 228, 0.6);
  border: 25px solid rgba(191, 195, 204, 0.5);
  padding: 40px;
}

@media only screen and (max-width: 575px) {
  .architecture-hero-text-box {
    padding: 30px 10px;
  }
}

.architecture-hero-three {
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 800px;
}

.architecture-hero-three .hero-text {
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .architecture-hero-three {
    height: 700px;
  }
  .architecture-hero-three h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .architecture-hero-three {
    height: 600px;
  }
  .architecture-hero-three h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .architecture-hero-three {
    height: 490px;
  }
  .architecture-hero-three .corporate-hero-text {
    margin-top: 0px;
  }
  .architecture-hero-three h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .architecture-hero-three {
    height: 480px;
  }
  .architecture-hero-three .corporate-hero-text {
    margin-top: 0px;
  }
  .architecture-hero-three h1 {
    font-size: 30px;
  }
}

.right-hero-slider {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 1040px;
  height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .right-hero-slider {
    max-width: 840px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .right-hero-slider {
    max-width: 640px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .right-hero-slider {
    max-width: 440px;
  }
}

@media only screen and (max-width: 767px) {
  .right-hero-slider {
    max-width: 100%;
  }
}

.right-single-hero {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.right-single-hero p {
  height: 500px;
  width: 600px;
}

.right-single-hero::before {
  content: '';
  display: block;
  padding-top: 81%;
}

.architecture-hero-three-wrap {
  position: relative;
}

.architecture-hero-three-wrap .hero-swiper-pagination-number {
  bottom: 60px;
  z-index: 1;
  left: 0px;
  position: relative;
}

.architecture-hero-three-wrap .hero-swiper-pagination-number.swiper-pagination__right {
  padding-left: 70px;
}

.architecture-hero-three-wrap .hero-swiper-pagination-number.swiper-pagination__right::after {
  background: #273272;
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 50px;
  height: 1px;
}

.architecture-hero-three-wrap .swiper-pagination-bullet {
  text-align: center;
  line-height: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #273272;
  opacity: 1;
  background: transparent;
  margin-right: 30px;
}

.architecture-hero-three-wrap .swiper-pagination-bullet-active {
  color: #f3525a;
  background: transparent;
}

.dots-image {
  position: relative;
}

.dots-image .dots-inner-images {
  margin-left: 60px;
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .dots-image .dots-inner-images {
    margin-left: 30px;
    padding-top: 30px;
  }
}

.dots-image .dots-inner-images img {
  display: block;
  position: relative;
  z-index: 1;
}

.dots-image .dots {
  position: absolute;
  left: 0;
  top: 0;
  width: 75%;
  height: 85%;
  background: url(../images/bg/dots.png) 0 0 repeat;
}

.dots-image .dots-inner-images-2 {
  margin-left: 0px;
  padding-bottom: 38px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .dots-image .dots-inner-images-2 {
    margin-left: 30px;
    padding-top: 30px;
  }
}

.dots-image .dots-inner-images-2 img {
  display: block;
  position: relative;
  z-index: 1;
}

.dots-image .dots-2 {
  position: absolute;
  left: -40px;
  bottom: 0px;
  width: 85%;
  height: 65%;
  background: url(../images/bg/dots.png) 0 0 repeat;
}

.dots-image .dots-inner-images-3 {
  margin-left: 0px;
  position: relative;
}

.dots-image .dots-inner-images-3 img {
  display: block;
  position: relative;
  z-index: 1;
}

.dots-image .dots-3 {
  position: absolute;
  right: -30px;
  bottom: -40px;
  width: 85%;
  height: 110%;
  background: url(../images/bg/dots.png) 0 0 repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .dots-image .dots-3 {
    right: -0px;
  }
}

.about-us-offset {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-us-offset {
    padding-left: 0px;
  }
}

.architecture-process-title {
  background: #f7f7f7;
  padding: 35px 25px 20px 35px;
  max-width: 439px;
}

.architecture-about-two {
  max-width: 425px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .architecture-about-two {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.architecture-about-two .text-size-lg {
  font-size: 39px;
}

.team-content-right {
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .team-content-right {
    padding-left: 0px;
  }
}

.square-flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  height: 359px;
  margin-top: 30px;
}

.square,
.square2 {
  width: 100%;
  height: 100%;
}

.square > img,
.square2 > img {
  width: 100%;
}

.square {
  background-size: cover;
  background-position: center center;
  -ms-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.square > img {
  width: 100% !important;
}

.square-flip:hover .square {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.square-flip .square2 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden !important;
  /* Safari */
  backface-visibility: hidden;
}

.square-flip .square2 {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}

.square-flip .square2 .flip-overlay {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.square-flip:hover .square2 {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-box-shadow: 0px 0px 62.9136px 5.0864px rgba(39, 50, 114, 0.33);
  -moz-box-shadow: 0px 0px 62.9136px 5.0864px rgba(39, 50, 114, 0.33);
  box-shadow: 0px 0px 62.9136px 5.0864px rgba(39, 50, 114, 0.33);
}

.square-container2 {
  z-index: 99999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 20px 10px;
}

.square-container2 a {
  color: #f3525a;
}

.architecture-font {
  font-size: 24px;
}

.architecture_service-order-list {
  padding-left: 40px;
}

.architecture_service-order-list li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.architecture_service-order-list li::after {
  left: 0;
  top: 8px;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  background: #273272;
}

.architecture_service-info-list {
  max-width: 550px;
  background: #273272;
  padding: 40px 40px;
}

.architecture_service-info-list li {
  color: #fff;
}

.architecture_service-info-list li span {
  font-size: 24px;
  margin-right: 5px;
  font-weight: 500;
}

/*=============================================
=           26. StartUp Pages Css             =
=============================================*/
.start-up-hero {
  height: 888px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .start-up-hero {
    height: 700px;
  }
  .start-up-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .start-up-hero {
    height: 600px;
  }
  .start-up-hero h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .start-up-hero {
    height: 490px;
  }
  .start-up-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .start-up-hero h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .start-up-hero {
    height: 480px;
  }
  .start-up-hero .corporate-hero-text {
    margin-top: 0px;
  }
  .start-up-hero h1 {
    font-size: 30px;
  }
}

.start-up-hero.bg-img {
  background-position: bottom center;
}

@media only screen and (max-width: 767px) {
  .start-up-hero.bg-img {
    background-position: center;
  }
}

.height-vh {
  height: 100vh;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-1 h4 {
  animation-delay: 0.4s;
  animation-name: fadeInLeft;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-1 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInLeft;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-1 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInLeft;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-2 h4 {
  animation-delay: 0.4s;
  animation-name: rollIn;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-2 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInLeft;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-2 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInLeft;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-3 h4 {
  animation-delay: 0.4s;
  animation-name: fadeInUp;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-3 h1 {
  animation-delay: 0.6s;
  animation-name: fadeInUp;
}

.start-up-hero-wrap .swiper-slide-active .layer-animation-3 .hero-button {
  animation-delay: 0.8s;
  animation-name: fadeInUp;
}

.start-up-hero-wrap {
  position: relative;
}

.start-up-hero-wrap .hero-swiper-pagination-number {
  position: absolute;
  bottom: 60px;
  z-index: 1;
  left: 15px;
}

.start-up-hero-wrap .hero-swiper-pagination-number.swiper-pagination__left {
  padding-left: 70px;
}

.start-up-hero-wrap .hero-swiper-pagination-number.swiper-pagination__left::after {
  background: #273272;
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 50px;
  height: 1px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .start-up-hero-wrap .hero-swiper-pagination-number {
    bottom: 20px;
    right: 20px;
  }
}

.start-up-hero-wrap .swiper-pagination-bullet {
  text-align: center;
  line-height: 24px;
  font-size: 24px;
  font-weight: 600;
  color: #273272;
  opacity: 1;
  background: transparent;
  margin-right: 30px;
}

.start-up-hero-wrap .swiper-pagination-bullet-active {
  color: #f3525a;
  background: transparent;
}

.start-up-bg {
  background: #f8f5ff;
}

.start-up-patterns-image-01 {
  position: relative;
}

.start-up-patterns-image-01::before {
  position: absolute;
  top: -40px;
  left: 0;
  content: '';
  background: url(../images/patterns/startup-pattens-01.png) top 0px left no-repeat;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.start-up-patterns-image-02 {
  position: relative;
  z-index: 0;
}

.start-up-patterns-image-02::before {
  position: absolute;
  top: -395px;
  right: 0;
  content: '';
  background: url(../images/patterns/startup-pattens-02.png) top 0px right no-repeat;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.startup-project-section-bg {
  position: relative;
  z-index: 0;
}

.startup-project-section-bg::before {
  position: absolute;
  bottom: 0;
  right: 0;
  content: '';
  background: url(../images/patterns/startup-pattens-03.png) bottom 0px right no-repeat;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.about-us-offset-right {
  padding-right: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-us-offset-right {
    padding-right: 0px;
  }
}

.ht-service-box.style-solutions {
  margin-top: 50px;
}

.ht-service-box.style-solutions .service-icon {
  margin-bottom: 28px;
}

.ht-service-box.style-solutions .service-content {
  max-width: 215px;
}

.ht-service-box.style-solutions .service-content p {
  font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .ht-service-box.style-solutions {
    margin-top: 30px;
  }
}

.ht-service-box.style-solutions-two {
  margin-top: 50px;
  text-align: center;
}

.ht-service-box.style-solutions-two .service-icon {
  margin-bottom: 28px;
  background: #f4effe;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  margin: auto;
}

.ht-service-box.style-solutions-two .service-icon img {
  width: 100px;
  height: 100px;
}

.ht-service-box.style-solutions-two .service-content {
  background: #f8f5ff;
  text-align: center;
  padding: 93px 15px 35px;
  margin-top: -65px;
}

.ht-service-box.style-solutions-two .service-content p {
  font-weight: 300;
  max-width: 215px;
  margin: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-service-box.style-solutions-two .service-content h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .ht-service-box.style-solutions-two {
    margin-top: 30px;
  }
}

.col-06__left-630 {
  width: 670px;
  max-width: 100%;
  margin-top: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left-630 {
    float: none;
    margin-top: 30px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.start-up-right-740 {
  width: 740px;
  max-width: 100%;
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .start-up-right-740 {
    float: none;
    padding-left: 0px;
  }
}

.start-up-left-740 {
  width: 740px;
  max-width: 100%;
  padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .start-up-left-740 {
    float: none;
    padding-left: 0px;
  }
}

.startup-project-wrap {
  text-align: center;
}

.startup-project-wrap::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: url(../images/patterns/startup-project-bg.png) top no-repeat;
  z-index: -0;
}

.startup-project-wrap a {
  padding-top: 30px;
  display: block;
  z-index: 1;
  position: relative;
}

.startup-project-box {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.startup-project-box::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: url(../images/patterns/startup-project-lg-bg.png) top no-repeat;
  z-index: -0;
}

.startup-project-box a {
  padding-top: 50px;
  display: block;
  z-index: 1;
  position: relative;
}

.startup-project-box .image-box {
  position: relative;
}

.startup-project-box .image-box:hover .startup-project-hover {
  background-color: rgba(229, 234, 255, 0.9);
}

.startup-project-box .image-box:hover .startup-project-hover .post-overlay-title {
  opacity: 1;
  transform: translateY(-50%);
}

.startup-project-box .image-box .startup-project-hover {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
}

.startup-project-box .image-box .startup-project-hover .post-overlay-title {
  opacity: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  padding: 0 20px;
  transform: translateY(0%);
  position: absolute;
  bottom: 2.71rem;
  color: #000;
  transition: .3s ease;
}

.startup_service-info-list {
  max-width: 550px;
}

.startup_service-info-list span {
  font-size: 24px;
  margin-right: 5px;
  font-weight: 500;
}

.pagination-project-1 .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  margin-right: 10px;
}

.pagination-project-1 .swiper-pagination-bullet:before {
  background: #273272;
  z-index: 1;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pagination-project-1 .swiper-pagination-bullet:hover:before {
  width: 10px;
  height: 10px;
  background: #f3525a;
}

.pagination-project-1 .swiper-pagination-bullet-active:before {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: #f3525a;
}

.start-up-patterns-image-04 {
  position: relative;
}

.start-up-patterns-image-04::before {
  position: absolute;
  top: 250px;
  left: 0;
  content: '';
  background: url(../images/patterns/startup-pattens-01.png) top 0px left no-repeat;
  height: 100%;
  width: 100%;
  z-index: -0;
}

.ht-tab__content {
  max-width: 400px;
  min-width: 290px;
  float: right;
}

.ht-tab__content-two {
  max-width: 400px;
  min-width: 290px;
  float: left;
  position: absolute;
}

@media only screen and (max-width: 575px) {
  .ht-tab__content-two {
    position: inherit;
  }
}

.img-content-start-up-team {
  margin-right: -120px;
}

.ht-tab-menu-team {
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
}

.ht-tab-menu-team .tab__item {
  padding: 0 20px;
  margin-top: 40px;
}

.ht-tab-menu-team .tab__item:first-child {
  margin-top: 100px;
}

.ht-tab-menu-team .tab__item:last-child {
  margin-top: -20px;
}

.ht-tab-menu-team .tab__item a {
  padding: 0;
}

.ht-tab-menu-team .tab__item a.active {
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

@media only screen and (max-width: 575px) {
  .ht-tab-menu-team .tab__item {
    width: 152px;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 575px) {
  .ht-tab-menu-team {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.ht-tab__content .single-team-box.style-02 .team-content, .ht-tab__content-two .single-team-box.style-02 .team-content {
  background: #f8f5ff;
  border-radius: 0 0 5px 5px;
}

.col-05__right-two {
  max-width: 500px;
  background: #f8f5ff;
}

.ht-tab-menu-team-two {
  padding: 0 50px 70px 150px;
}

.ht-tab-menu-team-two .tab__item {
  padding: 0 20px;
  margin-top: 40px;
  width: 50%;
}

.ht-tab-menu-team-two .tab__item a {
  height: 138px;
  width: 138px;
  padding: 0;
}

.ht-tab-menu-team-two .tab__item a.active {
  border-radius: 50%;
  box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
}

@media only screen and (max-width: 575px) {
  .ht-tab-menu-team-two .tab__item a {
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .ht-tab-menu-team-two .tab__item {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .ht-tab-menu-team-two {
    padding: 0 0 30px  20px;
  }
}

/* .start_up_service-bg {
    background: url(../images/banner/banner-shop.jpg);
}

 */
.breadcrumb_inner-img {
  right: -150px;
  position: absolute;
  bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb_inner-img {
    right: 15px;
    width: 420px;
    top: -30%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb_inner-img {
    width: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .breadcrumb_inner-img {
    display: none;
  }
}

/*=====================================
=        27. Blog Pages Css           =
======================================*/
.lates-single-blog .blog-media a {
  display: block;
}

.lates-single-blog .blog-media a img {
  width: 100%;
}

.lates-single-blog .post-info.lates-blog-post-info {
  padding: 24px;
  box-shadow: 0px 0px 54px 0px rgba(39, 40, 114, 0.14);
}

.lates-single-blog .post-info.lates-blog-post-info .post-meta {
  margin-bottom: 10px;
}

.lates-single-blog .post-info.lates-blog-post-info .post-excerpt p {
  font-weight: 300;
}

.lates-single-blog .post-info.lates-blog-post-info .btn-text {
  margin-top: 10px;
  margin-top: 10px;
}

.lates-single-blog .post-info.lates-blog-post-info-02 {
  padding: 24px;
  background: #FBFBFB;
}

.lates-single-blog .post-info.lates-blog-post-info-02 .post-excerpt p {
  font-weight: 300;
}

.lates-single-blog .post-info.lates-blog-post-info-02 .btn-text {
  margin-top: 10px;
  margin-top: 10px;
}

.personal-post-previous-next ul {
  float: right;
}

.personal-post-previous-next ul li {
  display: inline-block;
  margin-left: 50px;
}

.personal-post-previous-next ul li a {
  color: #b8b9ba;
}

.personal-post-previous-next ul li a:hover {
  color: #273272;
  text-decoration: underline;
}

.comment-list {
  margin: 0;
  padding: 0;
  margin-top: 70px;
}

@media only screen and (max-width: 767px) {
  .comment-list {
    margin: 30px 0 0px 0px;
  }
}

.comment-list:first-child {
  margin-top: 50px;
}

.comment-list .comment {
  list-style-type: none;
  padding: 5px 0;
}

.comment-list .comment:last-child {
  padding-bottom: 0;
}

.comment-list .comment-author {
  float: left;
  border: 5px solid #f1f1f1;
  border-radius: 50%;
  box-shadow: 0px 0px 13px 0px rgba(1, 1, 1, 0.33);
}

.comment-list .comment-author img {
  border-radius: 50px;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment-author {
    max-width: 80px;
  }
}

.comment-list .comment-content {
  position: relative;
  overflow: hidden;
  margin-left: 140px;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment-content {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .comment-list .comment-content {
    margin-left: 90px;
  }
}

.comment-list .meta {
  margin-bottom: 12px;
}

.comment-list .meta .fn {
  font-size: 18px;
  color: #273272;
  display: inline-block;
  margin-bottom: 0;
}

.comment-list .meta .comment-datetime {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  font-size: 15px;
  color: #172540;
}

.comment-list .comment-actions a {
  margin-right: 20px;
  font-weight: 600;
  color: #333;
}

.comment-list .comment-actions a:hover {
  color: #273272;
}

.comment-list .children {
  margin: 70px 0 0px 80px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .children {
    margin: 30px 0 20px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .comment-list .children {
    margin: 30px 0 20px 0px;
  }
}

.comment-list .children li + li {
  margin-top: 35px;
}

.comment-list .comment-actions {
  margin-top: 5px;
  color: #ababab;
}

.comment-list .comment-actions .comment-datetime {
  display: inline-block;
  margin-right: 10px;
}

.comment-list .comment-actions .comment-reply-link {
  display: inline-block;
  font-weight: 600;
}

.comment-box-form {
  margin-top: 25px;
  position: relative;
}

.comment-box-form .comment-box textarea {
  border: 1px solid #ddd;
  width: 100%;
  height: 52px;
  padding: 10px 55px 10px 15px;
}

.comment-box-form .relative-btn {
  position: absolute;
  top: 0%;
  right: 0;
  border: none;
  background: transparent;
  font-size: 18px;
  height: 52px;
  padding: 10px 28px;
}

.comment-box-form .relative-btn:hover {
  color: #f3525a;
}

.blog-details-wrap .lates-blog-post-info-02 {
  padding: 35px 35px;
  background: #fbfbfb;
}

/*=============================================
=      28. Projct Details Pages Css         =
=============================================*/
.portfolio-details-section {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .portfolio-details-section {
    padding-top: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-section {
    padding-top: 10px;
  }
}

.portfolio-details-one-images {
  max-width: 540px;
}

.portfolio-details-grid-masonry {
  max-width: 720px;
}

.portfolio-details-content .portfolio-categories {
  margin-bottom: 10px;
  color: #f3525a;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.portfolio-details-table table {
  margin: 0 0 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  display: inline-table;
  overflow: hidden;
}

.portfolio-details-table .label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #333;
  margin-bottom: 12px;
}

.portfolio-details-table td:last-child {
  text-align: right;
  padding-right: 0;
}

.portfolio-details-table td {
  border: 0;
  background: none !important;
  padding: 17px 10px;
  padding-right: 10px;
  text-align: left;
}

.portfolio-details-table td:first-child {
  padding-left: 0;
}

.portfolio-details-table tr + tr {
  border-top: 1px solid #ededed;
}

.portfolio-details-table .portfolio-details-share a {
  padding: 0 10px;
  font-size: 15px;
  color: #ababab;
}

.portfolio-details-grid-masonry {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media only screen and (max-width: 767px) {
  .portfolio-details-grid-masonry {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-grid-masonry {
    margin-bottom: 30px;
  }
}

.portfolio-details-grid-masonry .portfolio-details-grid-image img {
  width: 100%;
}

.portfolio-details-five-images {
  position: relative;
}

.portfolio-details-five-images .caption-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  text-align: center;
}

.entry-portfolio-return-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
  font-size: 18px;
}

.portfolio-nav-links .nav-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

@media only screen and (max-width: 767px) {
  .portfolio-nav-links .nav-list {
    grid-template-columns: auto;
  }
}

.portfolio-nav-links .nav-list .next {
  text-align: right;
}

.portfolio-nav-links .nav-list .inner div {
  display: flex;
  align-items: center;
  min-height: 190px;
  padding: 20px 0;
}

.portfolio-nav-links .nav-list .prev img {
  margin-right: 28px;
}

.portfolio-nav-links .nav-list .next img {
  margin-left: 28px;
  order: 2;
}

.portfolio-nav-links .nav-list .next a {
  padding-right: 30px;
}

.portfolio-nav-links .nav-list .prev a {
  padding-left: 30px;
}

.portfolio-nav-links .nav-list .inner h6::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "FontAwesome";
  font-weight: 300;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.portfolio-nav-links .nav-list .prev h6::before {
  left: 15px;
  content: '\f104';
}

.portfolio-nav-links .nav-list .next h6::before {
  right: 15px;
  content: '\f105';
}

/*=====================================
=       29. Preview Page Css          =
======================================*/
.hero__purchase .btn:hover, .downlode-btn .btn:hover {
  background: #273272;
  color: #fff;
}

.header__actions--preview .ht-btn {
  background: #fff;
  color: #273272;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--preview {
    flex-basis: 70%;
  }
  .header__actions--preview .header__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.preview-hero-area {
  height: 880px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .preview-hero-area {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .preview-hero-area {
    height: 620px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero-area {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .preview-hero-area {
    height: auto;
  }
}

.preview-hero-bg {
  background: url(../images/preview/landing-header-preview-bg.png);
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  background-repeat: no-repeat;
}

.pr-img-01 {
  position: absolute;
  top: -4%;
  left: 28%;
}

@media only screen and (max-width: 767px) {
  .pr-img-01 {
    width: 130px;
    top: -10%;
    left: 8%;
  }
}

.pr-img-02 {
  position: absolute;
  top: 12%;
  right: 6%;
}

@media only screen and (max-width: 767px) {
  .pr-img-02 {
    right: -5%;
  }
}

.pr-img-03 {
  position: absolute;
  bottom: 10%;
  right: -10%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-img-03 {
    width: 250px;
    right: -10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-img-03 {
    width: 250px;
    right: -10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-img-03 {
    width: 200px;
    right: -20%;
  }
}

@media only screen and (max-width: 767px) {
  .pr-img-03 {
    width: 200px;
    right: -20%;
  }
}

@media only screen and (max-width: 575px) {
  .pr-img-03 {
    width: 200px;
    right: -30%;
  }
}

.pr-img-04 {
  position: absolute;
  bottom: 20%;
  right: 18%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-img-04 {
    bottom: 5%;
    right: 18%;
  }
}

@media only screen and (max-width: 767px) {
  .pr-img-04 {
    bottom: 2%;
    right: 50%;
  }
}

.preview-inner-img img {
  z-index: 4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-inner-img {
    max-width: 500px;
    margin: auto;
    margin-bottom: 30px;
  }
}

.preview-hero-text {
  z-index: 4;
}

.preview-hero-text h6 {
  color: #f3525a;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-hero-text {
    text-align: center;
  }
  .preview-hero-text .hero-button {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .preview-hero-text {
    text-align: center;
  }
}

.single-preview-item__wrap a {
  display: block;
}

.single-preview-item__wrap a:hover {
  transform: translateY(-5px);
}

.single-preview-item__wrap a:hover .single-preview-item__thumbnail .overlay {
  visibility: visible;
  opacity: 1;
}

.frame-screen {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 70px 40px -35px rgba(51, 51, 51, 0.13);
}

.frame-screen .dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 14px;
  height: 23px;
}

.frame-screen .dots .dot {
  margin: 0 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #dadada;
}

.frame-screen .single-preview-item__thumbnail {
  position: relative;
}

.frame-screen .single-preview-item__thumbnail .overlay {
  background-color: rgba(60, 70, 128, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  opacity: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.frame-screen .single-preview-item__thumbnail .btn-view-demo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff !important;
  pointer-events: none;
  min-width: 150px;
  color: #333;
}

.frame-screen .single-preview-item__info h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.87;
  padding: 14px 27px;
  margin-bottom: 0;
  text-align: left;
}

.layout-section-bg {
  background: url(../images/preview/landing-header-preview-bg.png);
  background-color: #f6f2ed;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.layouts-section-text {
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.ht-box-icon.preview-style {
  margin-left: auto;
  max-width: 420px;
  margin-top: 30px;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-box-icon.preview-style:hover {
  transform: translateY(-5px);
}

.ht-box-icon.preview-style .icon-box-wrap {
  display: flex;
}

.ht-box-icon.preview-style .icon-box-wrap .image {
  position: relative;
  flex-shrink: 0;
  margin: 0 27px 0 0;
  width: 114px;
  text-align: center;
  border-radius: 50%;
}

.prv-section-footer-bg {
  background: url(../images/preview/landing-header-preview-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-preview-content h6 {
  letter-spacing: 7px;
}
