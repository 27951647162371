/*================================  
=          17. Team Css          =
==================================*/

.single-team-box {
    &.style-01 {
        position: relative;
        text-align: center;
        margin-bottom: 50px;
        transition: all 0.3s ease-in-out;
        & .team-content {
            padding: 30px 15px;
            text-align: center;
            background: #fbfbfb;
            position: relative;
            &::after {
                position: absolute;
                background: $theme-color--default;
                height: 0%;
                width: 100%;
                left: 0;
                top: 0;
                content: '';
                transition: all 0.3s ease-in-out;
               
            }
            & h4 {
                color: $theme-color--default;
                margin-bottom: 5px;
                @media #{$desktop-device} {
                    font-size: 20px;
                }
            }
            & .text-box {
                z-index: 4;
                position: relative;
            }
        }
        
        &:hover {
            box-shadow: 0px 0px 54px 0px rgba(39, 50, 114, 0.14);
            & .team-content {
                &::after {
                    height: 100%;
                    z-index: 3;
                }
                & h4 {
                    color: $white;
                }
                & p {
                    color: $white;
                }
            }
            .social-creative-box {
                opacity: 1;
                visibility: visible;
                & .style-three {
                transform: translateY(-24px)
                }
            }
        }
        .social-creative-box {
            opacity: 0;
            visibility: hidden;
            z-index: 4;
            position: absolute;
            margin: auto;
            text-align: center;
            left: 0;
            right: 0;
        }
        .ht-social-networks {
            &.style-three {
                background: #F4F4F4;
                padding: 10px;
                margin: auto;
                text-align: center;
                max-width: 220px;
                margin-top: 0px;
                transition: all 0.5s ease-in-out;
                transform: translateY(30px);
                .item {
                    display: inline-block;
                    color: $theme-color--three;
                    margin-right: 35px;
                    font-size: 18px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    
    &.style-02 {
        text-align: center;
        transition: all 0.3s ease-in-out;
        
        .team-image {
            position: relative;
            & img {
                width: 100%;
            }
        }
        
        & .team-content {
            padding: 15px 15px;
            text-align: center;
            background: #ebebeb;
            position: relative;
            &::after {
                position: absolute;
                background: $theme-color--default;
                height: 0%;
                width: 100%;
                left: 0;
                top: 0;
                content: '';
                transition: all 0.3s ease-in-out;
               
            }
            & h4 {
                color: $theme-color--default;
                margin-bottom: 5px;
                @media #{$desktop-device}{
                    font-size: 20px;
                }
            }
            & .text-box {
                z-index: 4;
                position: relative;
            }
        }
        
        &:hover {
            & .team-content {
                &::after {
                    height: 100%;
                    z-index: 3;
                }
                & h4 {
                    color: $white;
                }
                & p {
                    color: $white;
                }
            }
            .ht-social-networks.style-four .item {
                left: 0;
                opacity: 1;
                visibility: visible;
                
            }
        }
        .ht-social-networks {
            &.style-four {
                position: absolute;
                bottom: 10px;
                right: 0;
                .item {

                    display: block;
                    left: 17px;
                    margin-right: 17px;
                    margin-bottom: 5px;
                    opacity: 0;
                    visibility: hidden;
                    position: relative;
                    text-align: center;
                    transition: all .3s ease 0s;
                    & a {
                        font-size: 12px;
                        border-radius: 50px;
                        color: $white;
                        height: 25px;
                        line-height: 25px;
                        width: 25px;
                        background-color: $theme-color--default;
                        &:hover {
                            background: $theme-color--two;
                        }
                    }
                    &:nth-child(1) {
                        transition-delay: 100ms;
                    }
                    &:nth-child(2) {
                        transition-delay: 150ms;
                    }
                    &:nth-child(3) {
                        transition-delay: 200ms;
                    }
                    &:nth-child(4) {
                        transition-delay: 200ms;
                    }

                }
            }
        }
    }
    
    &.style-03 { 
        margin-bottom: 30px;
        .team-image {
            position: relative;
            & a {
                display: block;
            }
            @media #{$small-mobile}{
                & img {
                    width: 100%;
                }
            }
        }
        .ht-social-networks {
            &.style-four {
                position: absolute;
                bottom: 10px;
                right: 0;
                .item {
                    display: block;
                    left: 17px;
                    margin-right: 17px;
                    margin-bottom: 5px;
                    opacity: 0;
                    visibility: hidden;
                    position: relative;
                    text-align: center;
                    transition: all .3s ease 0s;
                    & a {
                        font-size: 12px;
                        border-radius: 50px;
                        color: $white;
                        height: 25px;
                        line-height: 25px;
                        width: 25px;
                        background-color: $theme-color--default;
                        &:hover {
                            background: $theme-color--two;
                        }
                    }
                    &:nth-child(1) {
                        transition-delay: 100ms;
                    }
                    &:nth-child(2) {
                        transition-delay: 150ms;
                    }
                    &:nth-child(3) {
                        transition-delay: 200ms;
                    }
                    &:nth-child(4) {
                        transition-delay: 200ms;
                    }

                }
            }
        }
        .team-content {
            margin-top: 20px;
        }
        & h4 {
            color: $theme-color--default;
            margin-bottom: 5px;
            @media #{$desktop-device}{
                font-size: 20px;
            }
        }
        p {
            font-weight: 300;
        }
        
        &:hover {
            .ht-social-networks.style-four .item {
                left: 0;
                opacity: 1;
                visibility: visible;
                
            }
        }
        
    }
}
.team-style-3-title {
    min-height: 400px;
    top: 0;
    height: 100%;
    padding: 26px;
    position: relative;
    margin-right: 20px;
    @media #{$tablet-device,$large-mobile}{
        margin-right: 0px;
        min-height: 200px;
    }
    @media #{$small-mobile}{
        margin-right: 0px;
        min-height: 260px;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: rgba(39, 50, 114, 0.95);
    }
    
    & .team-style-3-title-bg {
        transform: rotate(-90deg) translateY(-50%);
        position: absolute;
        bottom: 140px;
        min-width: 340px;
        max-width: 300px;
        left: 0%;
        @media #{$tablet-device,$large-mobile}{
            transform: rotate(0deg) translateY(0%);
            padding: 20px;
            bottom: auto;
            min-width: 95%;
        }
        /* @media #{$large-mobile}{
            min-height: 200px;
            bottom: 140px;
            min-width: 340px;
            max-width: 300px;
            left: 0%;
            
        } */
        & h3 {
            color: $white;
        }
        & p {
            color: $white;
        }
    }
}
